import React from "react";
// Customizable Area Start
import {
  Container,
  Grid,
  Paper,
  Box,
  Input,
  FormLabel,
  Button,
  Modal,
  CircularProgress
} from "@material-ui/core";


import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import SustainHeader from "../../../components/src/SustainHeader.web";
import SustainFooter from "../../../components/src/SustainFooter.web";
const imageHand = require("../assets/hand.png");
export const configJSON = require("./config");

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },

  overrides: {
    MuiContainer: {
      root: {
        paddingLeft: 0,
        paddingRight: 0,
        "@media (min-width: 600px)": {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
  },
});
// Customizable Area End

import ContactusController, {  Props } from "./ContactusController";


export default class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
 
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={false}>
          <Paper elevation={0} style={bodyStyle.paperStyle}>
            {/* ------------------------------------- App bar ---------------------------------------------------- */}
            <SustainHeader />
            {/* ------------------------------------- Content terms and conditions ------------------------------- */}
            <Box sx={bodyStyle.sectionStyle}>
              <Box sx={{ flexWrap:"true", ...contactUsStyle.sectionContainer}}>
                <Box>
                  <p style={contactUsStyle.formHeadingStyle}>
                    {configJSON.contactUsHeaderFirstLine}
                  </p>
                  <p style={contactUsStyle.formHeadingStyle}>
                    {configJSON.contactUsHeaderSecondLine}
                    <img
                      style={{ transform: "translate(12.5%,12.5%)", height:"5vw", width: "5vw" }}
                      src={imageHand}
                      alt="handImage"
                    />
                  </p>
                </Box>
                <form style={{position:"relative", marginBottom: "0px"}} onSubmit={this.btnSubmitProps.onPress}>
                  <Box sx={{display:"flex", flexWrap:"wrap"}} >
                    <Box style={{ width: "34.12vw" }} >
                      
                      <FormLabel
                        style={contactUsStyle.inputLabel}
                        component="legend"
                        htmlFor="nameInput"
                      >
                       {configJSON.namePlaceHolder}
                      </FormLabel>
                      
                      <Input
                        data-test-id="txtName"
                        disableUnderline={true}
                        value={this.state.name}
                        onChange={this.setNameState}
                        style={{...contactUsStyle.inputField, border: this.state.inputBorder ==="nameInput"? "0.18vw solid #7bc149": "none"}}
                        id="nameInput"
                        type="text"
                        autoFocus
                        onFocus={this.setOutline}
                        />
                         <div style={contactUsStyle.alertText}>{this.errorMessage().nameErrorMsg}</div>
                      
                    </Box>
                    <Box style={{width:"34.12vw", marginLeft:"10.2vw"}} >
                      <FormLabel
                        style={contactUsStyle.inputLabel}
                        component="legend"
                        htmlFor="emailInput"
                      >
                        {configJSON.emailPlaceHolder}
                      </FormLabel>
                      <Input
                        data-test-id="txtEmail"
                        disableUnderline={true}
                        value={this.state.email}
                        onChange={this.setEmailState}
                        style={{ ...contactUsStyle.inputField, border: this.state.inputBorder ==="emailInput" ? "0.18vw solid #7bc149" : "none" }}
                        id="emailInput"
                        type="text"
                        onFocus={this.setOutline}
                      />
                      <div style={contactUsStyle.alertText}>{this.errorMessage().emailErrorMsg}</div>
                    </Box>
                    <Box style={{width:"34.12vw"}}>
                      <FormLabel
                        style={contactUsStyle.inputLabel}
                        component="legend"
                        htmlFor="mobileInput"
                      >
                         {configJSON.numberPlaceHolder}
                      </FormLabel>
                     
                      <Input
                        data-test-id="txtPhoneNumber"
                        disableUnderline={true}
                        value={this.state.phoneNumber}
                        onChange={this.setPhoneNumberState}
                        style={{...contactUsStyle.inputField, border: this.state.inputBorder ==="mobileInput"? "0.18vw solid #7bc149": "none"}}
                        id="mobileInput"
                        type="text"
                       
                        onFocus={this.setOutline}
                        
                      />
                      <div style={contactUsStyle.alertText}>{this.errorMessage().phoneNoErrorMsg}</div>
                    </Box>
                    <Box style={{width:"34.12vw", marginLeft:"10.2vw"}}>
                      <FormLabel
                        style={contactUsStyle.inputLabel}
                        component="legend"
                        htmlFor="messageInput"
                      >
                        {configJSON.commentsPlaceHolder}
                      </FormLabel>
                      <textarea
                        data-test-id="txtComments"
                        value={this.state.comments}
                        onChange={this.setCommentsState}
                        style={{ ...contactUsStyle.multiLineInputField, outline: this.state.inputBorder ==="messageInput" ? "0.18vw solid #7bc149" : "none" }}
                        id="messageInput"
                        
                        rows={4}
                        onFocus={this.setOutline}
                        
                      />
                      <div style={contactUsStyle.alertText}>{this.state.messageIsNull ? `${configJSON.errorAllFieldsAreMandatory}` : " "}</div>
                    </Box>
                    <Box>
                      <Button
                        data-test-id="btnSubmit"
                        style={contactUsStyle.buttonEl}
                        size="large"
                        variant="contained"
                        type="submit"
                       
                      >
                        {configJSON.submitButtonText}
                      </Button>
                    </Box>
                  </Box>
                </form>
              </Box>
              <Modal
                open={this.state.isVisible}
                onClose={this.hideModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box sx={contactUsStyle.modalContainer}>
                  <Box style={{ textAlign: "center", fontSize: "2.5vw" }} >
                   {this.state.isLoadingMessage? <CircularProgress color="inherit" />: this.errorMessage().modalDisplayMessage}
                  </Box>
                  
                    <Button
                      data-test-id="closeBtn"
                      onClick={this.hideModal}
                      style={contactUsStyle.modalCloseButton}
                      disabled={this.state.isLoadingMessage}
                    >
                     {configJSON.closeModalButtontext}
                    </Button>
                
                </Box>
              </Modal>
            </Box>

            {/* ------------------------------------- footer---------------------------------------------------- */}
            <Grid container style={bodyStyle.footerStyle}>
            {this.state.footer &&  <SustainFooter footer={this.state.footer} /> }
            </Grid>
            {/* ------------------------------------- end ---------------------------------------------------- */}
          </Paper>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const bodyStyle = {
  paperStyle: {
    minHeight: "100vh",
    width: "100vw",
    display: "flex",
    flexDirection: "column" as "column",
  },

  footerStyle: {
    display: "flex",
    backgroundColor: "#7BC149",
    minHeight: "23vw",
    padding: "3vw",
    marginTop: "auto",
  },

  sectionStyle: {
    display: "flex",
    marginTop: "4.6vw",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "11.6vw",
    position: "relative" as "relative"
  }
};


const contactUsStyle = {
  formHeadingStyle: {
    fontFamily: "BCAlphapipeTSB-Bold",
    fontSize: "5vw",
    letterSpacing: "0.23vw",
    fontWeight: 400,
    color: "#3e8010",
    margin: 0,
  },
  sectionContainer: {
    display: "flex",
    flexDirection: "column",
    width: "78.5vw",
  },
  inputLabel: {
    color: "#3e8010",
    fontFamily: "Avenir-Medium",
    fontSize: "1.5vw",
    marginBottom: "1.75vw",
    marginTop: "1vw",
    width: "34.12vw",
  },
  multiLineInputField: {
    width: "34.12vw",
    height: "13vw",
    borderRadius: "0.68vw",
    backgroundColor: "#f6fbf2",
    fontSize: "1.5vw",
    paddingLeft: "2px",
    outline: "none",
    border: "none",
    resize: "none" as "none"
  },
  inputField: {
    height: "4.5vw",
    width: "34.12vw",
    borderRadius: "0.68vw",
    paddingLeft: "0.08vw",
    backgroundColor: "#f6fbf2",
    fontSize: "1.5vw",
    outline: "none",
  },
  buttonEl: {
    backgroundColor: "#7bc149",
    padding: "8px 10px",
    marginBottom: "1.5vw",
    color: "#fff",
    width: "12.5vw",
    height: "3.75vw",
    fontSize: "1.25vw",
    fontFamily: "Avenir-Medium",
    borderRadius: "5rem",
    textTransform: "none" as "none",
    position: "absolute" as "absolute",
    bottom: "0",
    left: "0"
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "20vh",
    width: "30vw",
    borderRadius: "4vw",
    backgroundColor: "#fff",
    padding: "4.5vw",
    fontSize: "1.5vw"
  },
  modalCloseButton: {
    backgroundColor: "#7bc149",
    textTransform: "none" as "none",
    color: "#fff",
    width: "12.5vw",
    height: "3.75vw",
    fontSize: "1.5vw",
    borderRadius: "5rem",
    marginTop: "3vw"
  },
  alertText: {
    color: "red",
    height: "1.5vw",
    fontSize: "1.5vw",
    marginTop: "0.5vw"
  }
};
// Customizable Area End

