import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { GlobalContext } from "../../../components/src/useContext";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import React from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  infoblogData: any;
  infoPageLoader: boolean;
  recentBlogs: any;
  isIFrameLoaded: boolean;
  footer:any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class InfoPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  static contextType = GlobalContext
  iframeRef: any = React.createRef();
  apiblogCallById: string = "";
  apirecentblogsCallId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      infoblogData: {},
      infoPageLoader: false,
      recentBlogs: [],
      isIFrameLoaded: true,
      footer:{}
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    // ---------------------  BLOG BY ID response start -----------------------
    if (this.apiblogCallById === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse) {
        this.setState({
          infoblogData: apiResponse.data,
          infoPageLoader: false
        })
        this.videoLoadHandler(apiResponse.data,window)
      }

    }
    // ---------------------  BLOG BY ID response ends -----------------------
    // ---------------------  Recent BLogs response start -----------------------
    if (this.apirecentblogsCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse) {
        this.setState({
          recentBlogs: apiResponse.data,
        })
      }

    }
    // ---------------------  Reccent Blogs response ends -----------------------
    // Customizable Area End
  }


  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.setState({footer:  await JSON.parse( await getStorageData('footer', false))})
    this.getBlogById();
    this.getRecentBlogs();
    window.scrollTo(0, 0)

  }
  ///Recent BLOG BY ID Navigation start///
  videoLoadHandler = (infoblogData: any,window:any) => {
    this.setState({isIFrameLoaded:true})
    let embededRegex = /^.*([A,Z] [a,z][0,9]\/|v\/|u\/\w\/|embed)([^#&?]*).*/
    if (infoblogData.attributes.blog_type === configJSON.videoBlogTypeText && infoblogData.attributes?.video_link != null) {
      if (!embededRegex.test(infoblogData.attributes?.video_link)) {
        let temp = infoblogData
        temp.attributes.video_link = temp.attributes.video_link + "&output=embed"
        window.open(temp.attributes.video_link, "_blank");
        this.setState({
          isIFrameLoaded: false,
          infoblogData: temp
        })
      }
    }

    if (infoblogData.attributes.blog_type === 'Link' && infoblogData.attributes?.link != null) {
      if (!embededRegex.test(infoblogData.attributes?.link)) {
        window.open(infoblogData.attributes?.link , "_blank");
        this.setState({
          isIFrameLoaded: false,
        })
      }
    }
  }
  ///Recent BLOG BY ID Navigation end///
  ///Back Arrow Icon Navigation start///
  backArrowBtnHandler = () => {
    this.props.navigation.navigate('ElasticSearch')
  }
  ///Back Arrow Icon Navigation end///
  ///Recent BLOG BY ID Navigation start///
  recentBlogsInfoById = (id: any, window: any): any => {
    setStorageData('cardid', id)
    this.getBlogById()
    window.scrollTo(0, 0)
  }
  ///Recent BLOG BY ID Navigation end///
  ///BLOG BY ID api request start///
  getBlogById = async () => {
    const recentBlogId = await getStorageData('cardid', false)
    const header = {
      "Content-Type": configJSON.contentTypeApi,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiblogCallById = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.blogByIdCallUrl + `${recentBlogId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  ///BLOG BY ID api request end///
  ///BLOG BY ID api request start///
  getRecentBlogs = () => {

    const header = {
      "Content-Type": configJSON.contentTypeApi,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apirecentblogsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.recentBlogsCallUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  ///BLOG BY ID api request end///
  // Customizable Area End
}
