import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { GlobalContext } from "../../../components/src/useContext";
import { setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  landing_WhatWeAre:any,
  landing_VisionMission:any,
  landing_Services:any,
  footer:any,
  team:any,
  whatWeAre:any,
  servicesData: any;
  noServicesMsg : string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
    // Customizable Area Start
    static contextType = GlobalContext
    apiDynamicContentCallId: string = "";
    apiTeamCallId: string = "";
    apiFooterCallId: string = "";
    servicesApiCallId: any;
    // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      landing_WhatWeAre:[],
      landing_VisionMission:[],
      landing_Services:[],
      footer:[],
      team:[],
      whatWeAre:[],
      servicesData: [],
      noServicesMsg: ''
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start


     // ---------------------  Dynamic content response start -----------------------
     if (this.apiDynamicContentCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      if (apiResponse) {
           this.setState({landing_WhatWeAre:apiResponse.data[0]?.landing.data[0]?.attributes})
           this.setState({landing_VisionMission:apiResponse.data[0]?.landing.data[1]?.attributes})
           this.setState({landing_Services:apiResponse.data[0]?.landing.data[2]?.attributes})
           this.setState({whatWeAre:apiResponse.data[3]?.what_we_are.data[0]?.attributes})
      }

     }
    // ---------------------  Dynamic content response ends -----------------------
     // ---------------------  Team response start -----------------------
     if (this.apiTeamCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      if (apiResponse) {
        this.setState({team:apiResponse.data})
       }

      }
    // ---------------------  Team response ends -----------------------
     // ---------------------  Footer start -----------------------
     if (this.apiFooterCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      if (apiResponse) {
            this.setState({footer:apiResponse.data?.attributes})
             this.context.StudentDispatch({ type: 'UPDATE_NAME', payload: apiResponse?.data?.attributes })
             setStorageData('footer',JSON.stringify(apiResponse.data?.attributes))
            }

    }
    // ---------------------  Footer ends -----------------------
    if (this.servicesApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse) {
        this.setState({ servicesData: apiResponse.data });
        this.setState({ noServicesMsg: apiResponse.message });
      }
    }
    
    // Customizable Area End
  }

  // Customizable Area Start
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  async componentDidMount() {
    super.componentDidMount();
     this.getDynamicContent();
     this.getTeam();
     this.getFooterDetails();
     this.getServicesID();
  }
    ///Dynamic Content api request start///
    getDynamicContent = () => {

      const header = {
        "Content-Type": configJSON.contentTypeApi,
       };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.apiDynamicContentCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.dynamicContentApirCallUrl
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiGetMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
     }

     ///Dynamic Content api request end///

    ///Team members api request start///
    getTeam = () => {

      const header = {
        "Content-Type": configJSON.contentTypeApi,
       };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.apiTeamCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.teamApirCallUrl
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiGetMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
     }

     ///Team members api request end///
    ///Footer api request start///
    getFooterDetails = () => {

      const header = {
        "Content-Type": configJSON.contentTypeApi,
       };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.apiFooterCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.footerApiCallUrl
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiGetMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
     }

     ///Footer api request end///

     getServicesID = () => {
      const header = {
        "Content-Type": configJSON.contentTypeApi,
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.servicesApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.servicesApiCallUrl 
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiGetMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };

     btnWhatWeAre=()=>{
      this.props.navigation.navigate('WhatWeAre')
     }
     btnService=()=>{
      this.props.navigation.navigate('ProductAndServices')
     }
  // Customizable Area End
}
