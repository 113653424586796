Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpDeleteMethod = "DELETE";

exports.contactUsApiContentType = "application/json";
exports.getContactUsAPiEndPoint = "contact_us/contacts";
exports.postContactUsApiEndPoint = "bx_block_contact_us/contacts";

exports.apiSuccessResponseMsg = "Message sent successfully";
exports.apiErrorResponseMsg = "Some error occured";


exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "Please fill this detail.";
exports.errorEmailNotValid = "Please enter valid email.";
exports.phoneNumberError = "Only 10 digit numeric values allowed.";
exports.nameNotValidError = "Only Alphabets Allowed."

exports.contactUsHeaderFirstLine = "Love to hear from you";
exports.contactUsHeaderSecondLine = "Get in Touch";
exports.submitButtonText = "Just Sent";
exports.closeModalButtontext = "Close";

exports.nameTitle = "Name";
exports.emailTitle = "Email";
exports.numberTitle = "Phone number";
exports.commentsTitle = "Comments";

exports.namePlaceHolder = "Your Name";
exports.emailPlaceHolder = "Your Email";
exports.numberPlaceHolder = "Your Mobile number";
exports.commentsPlaceHolder = "Message";

exports.labelTitleText = "contactus";
exports.labelBodyText = "contactus Body";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End