import React from "react";
// Customizable Area Start
import {
  Container,
  Typography,
  Grid,
  Paper,
} from "@material-ui/core";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import SustainHeader from "../../../components/src/SustainHeader.web";
import SustainFooter from "../../../components/src/SustainFooter.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },

  overrides: {
    MuiContainer: {
      root: {
        paddingLeft: 0,
        paddingRight:0,
        "@media (min-width: 600px)": {
          paddingLeft: 0,
          paddingRight:0,
        },
      },
      
    },
    
  },
});
// Customizable Area End

import TermsAndConditionsController, {
  Props,
} from "./TermsAndConditionsController";

export default class TermsAndConditions extends TermsAndConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
             <Container maxWidth={false} >
    <Paper elevation={0} style={webStyle.mainPaperStyleTerms}>
        {/* ------------------------------------- App bar ---------------------------------------------------- */}
        <SustainHeader/>
      {/* ------------------------------------- Content terms and conditions ------------------------------- */}
      <Grid container style={webStyle.gridVisionTerms}>
            <Paper elevation={0} style={webStyle.paperVisionStyleTerms}  >
                <Typography  style={webStyle.blockTitleTerms} >SUSTAINPEDIA </Typography>
                <Typography  style={ webStyle.blockHeaderTerms}>{this.state.terms.title} </Typography>
                <Typography   style={webStyle.blockContentTerms} 
                            dangerouslySetInnerHTML={{ __html: this.state.terms.description }} /> 
            </Paper>
          </Grid>
          
        {/* ------------------------------------- footer---------------------------------------------------- */}
        <Grid container style={webStyle.footerStyleTerms}>
        {this.state.footer &&  <SustainFooter footer={this.state.footer} /> }
        </Grid>
        {/* ------------------------------------- end ---------------------------------------------------- */}
        </Paper>
      </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainPaperStyleTerms:{ 
    minHeight: '100vh',
    width:'100vw',
    display:'flex',
     flexDirection:'column' as 'column',
  },

footerStyleTerms:{
  display:'flex',
  backgroundColor:'#7BC149',
  minHeight:'23vw',
  padding:"3vw",
  marginTop:'auto'
},

gridVisionTerms:{
  display:'flex',  
  alignItems:'center', 
  justifyContent:'center',
  marginTop:'4.5vw', 
  marginBottom:'4.5vw',
},
paperVisionStyleTerms:{ 
  boxSizing: 'border-box' as 'border-box',
  width:'89%'
},

blockTitleTerms:{
  color: '#000',
  fontFamily: "DIN2014-DemiBold",
  fontWeight: 400,
  fontSize:'1.25vw'
},
blockBigHeaderTerms:{
  color: '#3e8010',
  fontFamily: "BCAlphapipeTSB-Bold",
  fontWeight: 400,
  letterSpacing: '0.1vw',
  fontSize:'5vw'
},
blockHeaderTerms:{
  color: '#3e8010',
  fontFamily: "BCAlphapipeTSB-Bold",
  fontWeight: 400,
  letterSpacing: '0.15vw',
  fontSize:'3vw'
},
blockContentTerms:{
  color: '#000',
  fontFamily: "Avenir-Light",
  fontWeight: 400,
  fontSize:'1.25vw'
},


};
// Customizable Area End
