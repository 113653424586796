import React, { useState, useEffect } from "react";

import {
    Typography
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";


// Customizable Area Start
import Slider from "react-slick";


const CategoriesCaurosal = (props: any) => {

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        arrow: true
    };
    const { category, subcategoryGetID, getActiveID } = props;


    const [activeID, setactiveID] = useState("")
  

    useEffect(() => {
        if (activeID === "") {
            setactiveID((e) => e)
        }
    }, [activeID])


    const carouselId = sessionStorage.getItem('carouselId') || "";

    return (
        <div>
            {category?.length >= 0 &&
                <Slider {...settings}>
                    {
                        category.map((item: any) => {
                            return (
                                <div key={item?.attributes?.id} >

                                    <Typography style={{
                                        textAlign: 'center',
                                        backgroundColor: +carouselId == item?.attributes?.id ? "#7bc149" : '#ffffff',
                                        color: +carouselId  == item?.attributes?.id ? "#fff" : '#000',
                                        ...webStyle.teamHeader
                                    }}
                                        onClick={() => {
                                            setactiveID(item?.attributes?.id)
                                            subcategoryGetID(item?.attributes?.id);                  
                                        }
                                        }
                                    >{item?.attributes?.category_name}</Typography>
                                </div>
                            )
                        })
                    }
                </Slider>
            }
        </div>
    );

}

// Customizable Area Start
const webStyle = {
    teamStyle: {
        width: '15.7vw',
        height: '14.6vw',
        margin: ' auto'
    },
    teamHeader: {
        fontFamily: "DIN2014-DemiBold",
        fontWeight: 400,
        letterSpacing: '0.15vw',
        fontSize: '1.25vw',
        boxShadow: '0px 2px 14px 4px rgba(0,0,0,0.05)',
        borderRadius: '4px',
        padding: '1vw',
        cursor: 'pointer',
        border: '1px solid #fcfcfa'
    },
    teamSubHeader: {
        color: '#6f6c6c',
        fontFamily: "DIN2014-DemiBold",
        fontWeight: 400,
        letterSpacing: '0.15vw',
        fontSize: '1.12vw',
        margin: 0,
    },
};

export default CategoriesCaurosal;
  // Customizable Area End