import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { GlobalContext } from "../../../components/src/useContext"
import { setStorageData, getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  category:any;
  subcategory:any;
  seeMoreActive:boolean;
  search:string;
  categoryLoader:boolean;
  categoryActive:boolean;
  subcategoryLoader:boolean;
  message:string;
  getActiveID:boolean;
  searchResult:any;
  searchLoader:boolean;
  footer:any,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ElasticSearchController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  static contextType = GlobalContext
  apiCategoryCallId: string = "";
  apiSubCategoryCallId: string = "";
  apiSearchCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      category:[],
      subcategory:[],
      seeMoreActive:false,
      search:'',
      categoryLoader:false,
      categoryActive:false,
      subcategoryLoader:false,
      message:'',
      getActiveID:true,
      searchResult:[],
      searchLoader:false,
      footer:{},
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
         // ---------------------  CATEGORY MAIN response start -----------------------
         if (this.apiCategoryCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
          const apiResponse = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          
          if (apiResponse) {
            this.setState({categoryLoader:false})
            this.setState({categoryActive:true})
            this.setState({category:apiResponse.data})
           }
    
          }
        // ---------------------  CATEGORY MAIN response ends -----------------------
         // ---------------------  sub category response start -----------------------
         if (this.apiSubCategoryCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
          const apiResponse = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          
          if (apiResponse) {
            this.setState({subcategoryLoader:false})
            this.setState({subcategory:apiResponse.data})
            this.setState({message:apiResponse.message})
           }
        }
        // ---------------------  sub category  response ends -----------------------

        // ---------------------  search response start -----------------------
                 if (this.apiSearchCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
                  const apiResponse = message.getData(
                    getName(MessageEnum.RestAPIResponceSuccessMessage)
                  );
                  
                  if (apiResponse) {
                    this.setState({searchLoader:false})
                    this.setState({searchResult:apiResponse.data})
                   }
                }
                // ---------------------  search response ends -----------------------
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.setState({footer:  await JSON.parse( await getStorageData('footer', false))})
     this.getCategory();
    this.getFirstID();
  }


      ///CATERGORY MAIN api request start///
      getCategory = () => {
        this.setState({categoryLoader:true})
        const header = {
          "Content-Type": configJSON.contentTypeApi,
         };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.apiCategoryCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.categoryApiCallUrl
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.apiGetMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
       }
  
       ///CATERGORY MAIN api request end///
       ///Sub catergory api request start///

       subcategoryGetID=(id:string)=>{
        this.setState({categoryLoader:false})
        this.setState({categoryActive:true})
        this.setState({searchResult:[]})
        this.setState({search:''})
        this.setState({subcategoryLoader:true})
        this.setState({getActiveID:true})

        const header = {
          "Content-Type": configJSON.contentTypeApi,
         };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.apiSubCategoryCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.subCategoryApiCallUrl+`${id}`
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.apiGetMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
       }
       ///Sub catergory api request endt///

       ///Search api request start///

              getSearchResult=(search:string)=>{
               this.setState({searchLoader:true})
                this.setState({subcategory:[]})
                  const header = {
                  "Content-Type": configJSON.contentTypeApi,
                 };
            
                const requestMessage = new Message(
                  getName(MessageEnum.RestAPIRequestMessage)
                );
            
                this.apiSearchCallId = requestMessage.messageId;
                requestMessage.addData(
                  getName(MessageEnum.RestAPIResponceEndPointMessage),
                  configJSON.searchApiCallUrl+`${search}`
                );
            
                requestMessage.addData(
                  getName(MessageEnum.RestAPIRequestHeaderMessage),
                  JSON.stringify(header)
                );
            
                requestMessage.addData(
                  getName(MessageEnum.RestAPIRequestMethodMessage),
                  configJSON.apiGetMethodType
                );
                runEngine.sendMessage(requestMessage.id, requestMessage);
               }
               ///Search api request endt///
       
       getFirstID=()=>{
        const firstID:any=" "
        this.subcategoryGetID(firstID)
       }
       seeMoreHandler=()=>{
        this.setState({seeMoreActive:!this.state.seeMoreActive})
       }
       cardIDHandler=(cardID:string)=>{
       setStorageData('cardid',cardID)
       this.props.navigation.navigate("InfoPage")
        
       }
       searchChangeHandler=(e: React.ChangeEvent<HTMLInputElement>)=>{
        this.setState({search:e.target.value})
       }
       clearSearchHandler=()=>{
        this.setState({search:""})
        this.setState({getActiveID:false})
        this.setState({categoryActive:true})
        this.getFirstID()
       }

      handleKeyDown = (event:any) => {
        this.setState({getActiveID:false})
        if (event.key === 'Enter') {
            this.setState({getActiveID:false})
            this.setState({categoryActive:false})
            this.getSearchResult(this.state.search)
         }
      }
  // Customizable Area End
}
