import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import {
  getStorageData,
} from "../../../framework/src/Utilities";

// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  route?: any;
  // Customizable Area End
}
// Customizable Area Start
export interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
// Customizable Area End
interface S {
  // Customizable Area Start
  token: string;
  categoriesArray: any;
  category: string;
  subCategory: string;
  isVisible: boolean;
  dropdownCategoryStatus: boolean;
  activeModalType: string;
  selectedCategoryID: any;
  categoryLoader: boolean;
  tabValue: number;
  productCategory: any;
  productDetail: any;
  categoryActive: boolean;
  footer: any;
  getActiveID: boolean;
  productsData: any;
  subcategoryLoader: boolean;
  similarProduct: any;
  noProductsMsg: any;
  servicesData: any;
  noServicesMsg: any;
  serviceDetail: any;
  similarServices: any;
  search: string;
  searchProductsData: any;
  searchServicesData: any;

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CategoriessubcategoriesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCategoriesApiCallId: any;
  deleteCategoriesApiCallId: any;
  deleteSubCategoriesApiCallId: any;
  addCategoryApiCallId: any;
  addSubCategoryApiCallId: any;
  apiCategoryCallId: string = "";
  productsApiCallId: any;
  productDetailApiCallId: any;
  similarProductApiCallId: any;
  servicesApiCallId: any;
  serviceDetailApiCallId: any;
  similarServicesApiCallId: any;
  searchProductsApiCallId: any;
  searchServicesApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      token: "",
      categoriesArray: [],
      category: "",
      subCategory: "",
      isVisible: false,
      dropdownCategoryStatus: false,
      activeModalType: "",
      selectedCategoryID: [],
      categoryLoader: false,
      tabValue: 0,
      productCategory: [],
      productDetail: {},
      categoryActive: false,
      footer: {},
      getActiveID: true,
      productsData: [],
      subcategoryLoader: false,
      similarProduct: [],
      noProductsMsg: "",
      servicesData: [],
      noServicesMsg: "",
      serviceDetail: {},
      similarServices: [],
      search: '',
      searchProductsData: [],
      searchServicesData: []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.setState({
      footer: await JSON.parse(await getStorageData("footer", false)),
    });
    this.getProductCategory();




    const carouselId = sessionStorage.getItem("carouselId") || 0;
    if (+carouselId === 0) {
      this.callApis(1);
    } else {
      this.callApis(carouselId);
    }

    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };
  // Customizable Area End
  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const apiResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    // ---------------------  CATEGORY MAIN response start -----------------------
    if (
      this.apiCategoryCallId === apiRequestCallId) {


      if (apiResponse) {
        this.setState({ categoryLoader: false });
        this.setState({ categoryActive: true });
        this.setState({ productCategory: apiResponse.data });
      }
    }
    // ---------------------  CATEGORY MAIN response ends -----------------------

    // --------------------------------Search products  start-------------------------

    if (this.searchProductsApiCallId === apiRequestCallId) {


      if (apiResponse) {
        this.setState({ subcategoryLoader: false });
        this.setState({ productsData: apiResponse?.data?.data });
        this.setState({ noProductsMsg: apiResponse?.message }
        );
      }
    }
    // --------------------------------Search products  ends-------------------------

    // --------------------------------Search service  start-------------------------
    if (this.searchServicesApiCallId === apiRequestCallId) {

      if (apiResponse) {
        this.setState({ subcategoryLoader: false });
        this.setState({ servicesData: apiResponse?.data });
        this.setState({ noServicesMsg: apiResponse.message });
      }
    }

    // --------------------------------Search service  ends-------------------------

    // ---------------------  sub category (Products )response start -----------------------
    if (
      this.productsApiCallId === apiRequestCallId) {


      if (apiResponse) {
        this.setState({ subcategoryLoader: false });
        this.setState({ productsData: apiResponse?.data?.data });
        this.setState({ noProductsMsg: apiResponse.message });
      }
    }
    // ---------------------  sub category  (Products ) response ends -----------------------


    // ---------------------  product Detail  response starts -----------------------
    if (
      this.productDetailApiCallId === apiRequestCallId) {

      if (apiResponse) {
        this.setState({ categoryLoader: false, productDetail: apiResponse?.data });
      }
    }
    this.handleSimilarProductsApi(apiRequestCallId, apiResponse);
    this.handleServicesApi(apiRequestCallId, apiResponse);
    this.handleServiceDetailApi(apiRequestCallId, apiResponse);
    this.handleSimilarServicesApi(apiRequestCallId, apiResponse);

    // Customizable Area End
  }

  // Customizable Area Start



  handleSimilarProductsApi = (apiRequestCallId: any, apiResponse: any) => {
    if (this.similarProductApiCallId === apiRequestCallId) {
      if (apiResponse) {
        this.setState(
          { categoryLoader: false, similarProduct: apiResponse?.data }
        );
      }
    }
  }
  handleServicesApi = (apiRequestCallId: any, apiResponse: any) => {
    if (this.servicesApiCallId === apiRequestCallId) {
      if (apiResponse) {
        this.setState({ servicesData: apiResponse.data });
        this.setState({ noServicesMsg: apiResponse.message });
      }
    }
  }

  handleServiceDetailApi = (apiRequestCallId: any, apiResponse: any) => {
    if (this.serviceDetailApiCallId === apiRequestCallId) {
      if (apiResponse) {
        this.setState({ productDetail: apiResponse?.data }
        );
      }
    }
  }

  handleSimilarServicesApi = (apiRequestCallId: any, apiResponse: any) => {
    if (this.similarServicesApiCallId === apiRequestCallId) {
      if (apiResponse) {
        this.setState(
          { categoryLoader: false, similarServices: apiResponse?.data }
        );
      }
    }
  }

  ///CATERGORY MAIN api request start///

  getProductCategory = () => {
    this.setState({ categoryLoader: true });
    const header = {
      "Content-Type": configJSON.contentTypeApi,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCategoryCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productCategoryApiCallUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getProductsID = (id: any) => {
    this.setState({ subcategoryLoader: true });
    const header = {
      "Content-Type": configJSON.contentTypeApi,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.productsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productsApiCallUrl + `?category_id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSearchedProducts = (search: string) => {
    this.setState({ subcategoryLoader: true });
    const header = {
      "Content-Type": configJSON.contentTypeApi,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.searchProductsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productsApiCallUrl + `?search=${search}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSearchedServices = (search: string) => {
    this.setState({ subcategoryLoader: true });
    const header = {
      "Content-Type": configJSON.contentTypeApi,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.searchServicesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.servicesApiCallUrl + `?search=${search}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  //  /CATERGORY MAIN api request end///

  getProductDetails = (productId: number) => {
    this.setState({ categoryLoader: true });
    const header = {
      "Content-Type": configJSON.contentTypeApi,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.productDetailApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productDetailApiCallUrl + `${productId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSimilarProducts = (productId: number, categoryId: number) => {
    this.setState({ categoryLoader: true });
    const header = {
      "Content-Type": configJSON.contentTypeApi,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.similarProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.similarProductsAPiCallUrl +
      `?product_id=${productId}&category_id=${categoryId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getServicesID = (id: any) => {
    const header = {
      "Content-Type": configJSON.contentTypeApi,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.servicesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.servicesApiCallUrl + `?category_id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getServiceDetails = (serviceId: number) => {
    this.setState({ categoryLoader: true });
    const header = {
      "Content-Type": configJSON.contentTypeApi,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.serviceDetailApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.serviceDetailApiCallUrl + `${serviceId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSimilarServices = (serviceId: number, categoryId: number) => {
    this.setState({ categoryLoader: true });
    const header = {
      "Content-Type": configJSON.contentTypeApi,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.similarServicesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.similarServicesApiCallUrl +
      `?service_id=${serviceId}&category_id=${categoryId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getProductsFirstID = () => {
    const firstID: any = " ";
    this.getProductsID(firstID);
  };

  getServiceFirst = () => {
    const serviceFirstID: any = "";
    this.getServicesID(serviceFirstID);
  };

  callDetailPageApis = () => {
    const productCardID = localStorage.getItem('productCardID') || 0;
    const serviceCardID = localStorage.getItem('serviceCardID') || 0;
    const carouselId = sessionStorage.getItem('carouselId') || 0;

    if ((productCardID || 0)) {
      this.getProductDetails(+productCardID);
      this.getSimilarProducts(+productCardID, +carouselId);
    } else {
      this.getServiceDetails(+serviceCardID);
      this.getSimilarServices(+serviceCardID, +carouselId);
    }

  }

  callApis = (e: any) => {
    sessionStorage.setItem("carouselId", e);
    this.getProductsID(e);
    this.getServicesID(e);
  };

  OnClickCarousalDetailPage = (e: any) => {
    this.props.navigation.navigate("ProductAndServices");
    this.callApis(e);
  };

  handleTabChange = (event: any, newValue: number) => {
    this.setState({ tabValue: newValue });
  };

  ProductCardIDHandler = (cardID: number) => {
    this.props.navigation.navigate("ProductAndServiceDetailPage");
    localStorage.setItem("productCardID", `${cardID}`);
    localStorage.setItem("serviceCardID", "");
  };

  serviceCardClickHandler = (serviceCardID: number) => {
    this.props.navigation.navigate("ProductAndServiceDetailPage");
    localStorage.setItem("productCardID", "");
    localStorage.setItem("serviceCardID", `${serviceCardID}`);
  };


  similarProductsClickHandler = (productCardID: number, carouselId: number) => {

    localStorage.setItem("productCardID", `${productCardID}`);
    sessionStorage.setItem('carouselId', `${carouselId}`)
    this.getProductDetails(productCardID);
    this.getSimilarProducts(productCardID, carouselId);
    window.location.reload();
  }

  similarServicesClickHandler = (serviceCardID: number, carouselId: number) => {
    localStorage.setItem("serviceCardID", `${serviceCardID}`);
    sessionStorage.setItem('carouselId', `${carouselId}`)
    this.getServiceDetails(serviceCardID);
    this.getSimilarServices(serviceCardID, carouselId);
    window.location.reload();
  }

  knowMoreBtnHandler = () => {
    this.props.navigation.navigate("Contactus");
  };

  searchChangeHandler = (e: any) => {
    this.setState({ search: e.target.value }
    )
  }
  clearSearchHandler = () => {
    this.setState({ search: "" })
  }

  handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      sessionStorage.setItem("carouselId", "0");
      this.getSearchedProducts(this.state.search);
      this.getSearchedServices(this.state.search);
    }
  }

  handleSearchResults = () => {
    return(sessionStorage.getItem("carouselId") == "0")
  }

  handleSearchProduct404Img = ()=>{
    return(this.state.noProductsMsg && sessionStorage.getItem("carouselId") == "0")
  }
  handleSearchService404Img = ()=>{
    return(this.state.noServicesMsg && sessionStorage.getItem("carouselId") == "0")
  }

  // Customizable Area End
}
