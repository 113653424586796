import React from "react";
// Customizable Area Start
import {
  Container,
  Typography,
  Grid,
  Paper,
  Box,

} from "@material-ui/core";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
export const configJSON = require("./config");
import SustainHeader from "../../../components/src/SustainHeader.web";
import SustainFooter from "../../../components/src/SustainFooter.web";
import SustainCategory from "../../../components/src/SustainCategory.web";
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import CircularProgress from '@material-ui/core/CircularProgress';
import {error} from './assets';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },

  overrides: {
    MuiContainer: {
      root: {
        paddingLeft: 0,
        paddingRight:0,
        "@media (min-width: 600px)": {
          paddingLeft: 0,
          paddingRight:0,
        },
      },
     
    },
    MuiPaper:{
        rounded :{
        borderRadius:0,
    },
    root:{
      backgroundColor:'transparent',
      boxShadow:'none'
    },
  }
    
  },
});
// Customizable Area End

import ElasticSearchController, {
  Props,
} from "./ElasticSearchController";

export default class ElasticSearch extends ElasticSearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
     return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
             <Container maxWidth={false} >
    <Paper elevation={0} style={webStyle.mainPaperStyleWe}>
        {/* ------------------------------------- App bar ---------------------------------------------------- */}
       <SustainHeader/>
      {/* ------------------------------------- Category corousel ------------------------------- */}
      {!this.state.categoryLoader ? 
      <>
      <Grid container style={webStyle.gridVisionSearch}>
            <Paper elevation={0} style={webStyle.paperVisionStyleSearch}  >
            <div style={webStyle.categoryDivStyle}>
                 <SustainCategory category= {this.state.category}
                  subcategoryGetID={this.subcategoryGetID}
                  getActiveID={this.state.getActiveID}
                 />
             </div>    
            </Paper>
          </Grid>
    {/* ------------------------------------- Elastic Search Box-------------------------------  */}
      <Grid container style={webStyle.gridVisionSearch}>
            <Paper elevation={0} style={webStyle.paperVisionStyleSearchBox}  >
           <Box style={webStyle.searchBoxStyle}>
           <input type="text"
                  value={this.state.search}
                  name='search'
                  placeholder='search'
                  onChange={this.searchChangeHandler}
                  onKeyDown={this.handleKeyDown}
                  style={webStyle.searchInputStyle}
                  data-testid="searchText"
           />
           {
            this.state.search =="" ?  <SearchIcon  style={webStyle.searchIconStyle}/>
            :  <ClearIcon style={{cursor:'pointer', ...webStyle.searchIconStyle}} 
               data-testid="clearText" 
               onClick={this.clearSearchHandler}/>
           }
           
           </Box>
            </Paper>
          </Grid>
      </>
      :            
      <Box style={webStyle.boxCatStyle}>
      <CircularProgress style={{color:'#3e8010'}}/>
      </Box>
  }

      {/* ------------------------------------- Sub Categories ------------------------------- */}
      {this.state.categoryActive && 
      <>
      <Grid container style={webStyle.gridVisionSearch}>
            <Paper elevation={0} style={webStyle.paperVisionStyleSearch}  >
              <Grid container style={{ alignItems:'center', ...webStyle.flexGridStyle}}>
             
              {!this.state.subcategoryLoader ? 
              <>
                {
                  this.state.subcategory ?
                   this.state.subcategory.map((item:any)=>{
                    return(
                     <Grid item xs={12} key={item.id}
                     style={{flexDirection:'column',alignItems:'center',cursor:'pointer', ...webStyle.flexGridStyle}}
                     >
                      <Grid container style={{alignItems:'center', ...webStyle.flexGridStyle}}>
                      <Grid item xs={12}>
                            <Typography style={{marginBottom:'2vw', ...webStyle.cardHeader}}>{Object.keys(item)}</Typography>  
                            
                            </Grid>
                            </Grid>
                            <Grid container style={{
                              display:'flex', 
                              alignItems:'center', 
                              marginBottom:'1.5vw',
                              overflow:this.state.seeMoreActive ? 'visible':'hidden',    
                              height:this.state.seeMoreActive ? '100%':'24vw',    
                            }}>
                           {
                            item[`${Object.keys(item)}`].data.map((subcat:any)=>{
                              
                             return(
                             
                              <Grid item xs={4} key={subcat.id} style={{ alignItems:'stretch',  ...webStyle.flexGridStyle }}>
                              <Paper elevation={0} onClick={()=>{this.cardIDHandler(subcat.id)}}
                              style={webStyle.paperCardStyle}
                                
                                 >
                                  <img src={subcat?.attributes?.image} style={webStyle.imgStyle} alt='SustainMantra subcategory'></img>
                                  <Box style={{position:'relative'}}>
                                  <Paper elevation={0} 
                                  style={webStyle.greenTagStyle} >
                                    <Typography style={webStyle.blogTextStyle}>{subcat?.attributes?.blog_type} </Typography>
                                    </Paper>
                                    </Box>
                                
                                  <Paper elevation={0} style={{  height:'2vw' ,...webStyle.paperCardContent}}>
                                      <Typography align='left' style={webStyle.cardHeader}>{subcat?.attributes?.title}</Typography>
                                    </Paper>
                                    <Paper elevation={0} style={{ height:'3.5vw' ,...webStyle.paperCardContent}}>
                                      <Typography align='left' style={webStyle.cardContent}
                                      dangerouslySetInnerHTML={{ __html: subcat?.attributes?.description }}  />
                                 </Paper>
                              </Paper>
                             
                              </Grid>
                              
                             )
                            })
                           }
                           </Grid>  
                           <Grid style={webStyle.gridSeeMore}>
                            {
                              item[`${Object.keys(item)}`].data.length > 3 && 
                              <Typography style={webStyle.seeMoreStyle}
                              onClick={this.seeMoreHandler}
                              >
                                {this.state.seeMoreActive && 'See less' }
                                {!this.state.seeMoreActive && "See more"}
                                </Typography>  
                            }
                           
                           </Grid> 
                       </Grid>
                    )
                  })
                  
                  :
                  <Box style={webStyle.mesgBoxStyle}>
                  <Typography style={webStyle.cardContent}>{this.state.message}</Typography>
                  </Box>
                }
                </>
                :            
                <Box  style={webStyle.boxCatStyle}>
                <CircularProgress style={{color:'#3e8010'}}/>
                </Box>
                
            }  
                </Grid>
            </Paper>
          </Grid>

          </>
          }
            {/* ------------------------------------- Search Result cards ------------------------------- */}
            {!this.state.categoryActive && 
      <>
      <Grid container style={webStyle.gridVisionSearch}>
            <Paper elevation={0} style={webStyle.paperVisionStyleSearch}  >

              
              <Grid container style={{ alignItems:'center', ...webStyle.flexGridStyle}}>
             
                <Grid item xs={12}>
                    <Typography style={{marginBottom:'2vw', ...webStyle.cardHeader}}>Search Result</Typography>  
                </Grid>
              {!this.state.searchLoader ? 
              <>
              <Grid container style={webStyle.searchGridStyle}>
                {
                this.state.searchResult && this.state.searchResult.map((item:any)=>{
                 
                  return(
                    <>
                     {
                          item[`${Object.keys(item)}`].data.map((searchItem:any)=>{
                           return(
                             <Grid item xs={4} key={searchItem.id} style={{ alignItems:'stretch',  ...webStyle.flexGridStyle }}>
                            <Paper elevation={0} onClick={()=>{this.cardIDHandler(searchItem.id)}}
                            style={webStyle.paperCardStyle}
                              
                               >

                                <img src={searchItem?.attributes?.image} style={webStyle.imgStyle} alt='SustainMantra search'></img>
                                <Box style={{position:'relative'}}>
                                <Paper elevation={0} 
                                style={webStyle.greenTagStyle} >
                                 
                                  <Typography style={webStyle.blogTextStyle}>{searchItem?.attributes?.blog_type} </Typography>
                                  </Paper>
                                  </Box>
                              
                                <Paper elevation={0} style={{  height:'2vw' ,...webStyle.paperCardContent}}>
                                   
                                    <Typography align='left' style={webStyle.cardHeader}>{searchItem?.attributes?.title}</Typography>
                                  </Paper>
                                  <Paper elevation={0} style={{ height:'3.4vw' ,...webStyle.paperCardContent}}>
                                    <Typography align='left' style={webStyle.cardContent}
                                    dangerouslySetInnerHTML={{ __html: searchItem?.attributes?.description }}  />
                               </Paper>
                            </Paper>
                           
                            </Grid>
                            
                           )
                          })
                         }
                    </>
                  )
                 })
                  
                }
                </Grid>
                  {
                  !this.state.searchResult && 
                  <Box style={webStyle.errorBoxStyle}>
                    <img src={error} style={webStyle.imgErrorStyle} alt='SustainMantra error'></img>
                  <Typography style={webStyle.errorText}> {configJSON.errorMsg}</Typography>
                  </Box>
                 }
                </> 
                 :            
                <Box  style={webStyle.boxCatStyle}>
                <CircularProgress style={{color:'#3e8010'}}/>
                </Box>
                
            }  
                </Grid>
            </Paper>
          </Grid>

          </>
          }    
        {/* ------------------------------------- footer---------------------------------------------------- */}
        <Grid container style={webStyle.footerStyleSearch}>
        {this.state.footer &&  <SustainFooter footer={this.state.footer} /> }
        </Grid>
        {/* ------------------------------------- end ---------------------------------------------------- */}
        </Paper>
      </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainPaperStyleWe:{ 
    minHeight: '100vh',
    width:'100vw',
    display:'flex',
    flexDirection:'column' as 'column',
    alignItems:'center',
    justifyContent:'center',
  },

  footerStyleSearch:{
    display:'flex',
    backgroundColor:'#7BC149',
    minHeight:'23vw',
    padding:"3vw",
    marginTop:'auto'
},

gridVisionSearch:{
  display:'flex',  
  marginTop:'1vw', 
  alignItems:'center', 
  justifyContent:'center',
  marginBottom:'2.5vw',
  width:'96%',
},
paperVisionStyleSearch:{ 
  boxSizing: 'border-box' as 'border-box',
  width:'89%',
},
paperVisionStyleSearchBox:{ 
  boxSizing: 'border-box' as 'border-box',
  width:'89%',
  display:'flex',
  justifyContent:'flex-end',
  alignItems:'center',

},


blockBigHeaderSearch:{
  color: '#3e8010',
  fontFamily: "BCAlphapipeTSB-Bold",
  fontWeight: 400,
  letterSpacing: '0.1vw',
  fontSize:'5vw'
},
categoryTitle:{
  color: '#00a7e2',
  fontFamily: "Avenir-Medium",
  fontWeight: 400,
  letterSpacing: '0.15vw',
  fontSize:'1.5vw',
  margin:'1vw 0 0 0 ',
 },
 imgStyle:{
  height:'14vw',
  width:'24vw',
 },
 imgErrorStyle:{
  height:'21.8vw',
  width:'31.25vw',
 },
 cardHeader:{
  fontFamily: 'DIN2014-DemiBold',
  fontWeight: 400,
  fontSize: '1.5vw',
  color:'#3e8010',
  backgroundColor:'transparent'
},
errorText:{
  fontFamily: 'DIN2014-DemiBold',
  fontWeight: 400,
  fontSize: '1.5vw',
  color:'#000',
  marginTop:'2vw'
},

cardContent:{
  fontFamily: 'Avenir-Light',
  fontWeight: 400,
  fontSize: '1.25vw',
  color:'#6f6c6c',
  backgroundColor:'transparent'
},
seeMoreStyle:{
  fontFamily: 'DIN2014-DemiBold',
  fontWeight: 400,
  fontSize: '1.25vw',
  color:'#000',
  cursor:'pointer',
},
blogTextStyle:{
  fontFamily: 'DIN2014-DemiBold',
  fontWeight: 400,
  fontSize: '1.25vw',
  color:'#fff',
 },
 categoryDivStyle:{
  height:'100%' , 
  width:'100%',
},
flexGridStyle:{
  display:'flex', 
  justifyContent:'center'
},
paperCardStyle:{
  width:'26.8vw', 
  height:'24vw',
  display:'flex', 
  flexDirection:'column' as 'column',
   alignItems:'center', 
   justifyContent:'center',
  backgroundColor:'#F9F9F9',
   borderRadius:'0.5vw',
   marginBottom:'1.8vw'
   },
greenTagStyle:{
  width:'6vw',
  height:'2.5vw', 
  backgroundColor:' #7bc149',
  position:'absolute' as 'absolute',
  zIndex: 100,
  margin: '-13vw 0 0 5vw',
  display:'flex', 
  alignItems:'center',
  justifyContent:'center',
},
paperCardContent:{
  width:'24vw', 
  boxSizing: 'border-box' as 'border-box',
 overflow:'hidden'
 },
 searchIconStyle:{      
  height:'2.1vw', 
  width:'2vw'
},
searchBoxStyle:{
  height:'3.5vw', 
  width:'23vw' ,
  borderRadius: '28px',
  border: '1px solid #e6e6e6',
  backgroundColor: '#ffffff',
  display:'flex',
  justifyContent:'space-between',
  alignItems:'center',
  padding:'0 2.5vw'
},
searchInputStyle:{ 
  height:'3vw', 
 width:'22vw',
 outline: 'none',
  border:'none',
  backgroundColor:'transparent',
  boxShadow: 'none', 
  fontSize:'1.5vw',
  fontFamily: "DIN2014-DemiBold",
  fontWeight: 400,
  color:'#000',
  padding:'0 1vw 0 0'
},
boxCatStyle:{
  display:'flex',
  flexDirection:'column' as 'column',
  alignItems:'center',
  justifyContent:'center',
  width:'50%',
 },
 errorBoxStyle:{
  width:'100vw', 
  height:'100vh',
  display:'flex',
  flexDirection:'column' as 'column',
   justifyContent:'center',
   alignItems:'center',
  },
  mesgBoxStyle:{
   width:'100%', 
   display:'flex',
   justifyContent:'flex-start',
     
    },
  gridSeeMore:{
    display:'flex',  
    justifyContent:'flex-end',
    width: '93%'
  },
  searchGridStyle:{
    display:'flex',
    alignItems:'center', 
    justifyContent:'flex-start'
  },
};
// Customizable Area End
