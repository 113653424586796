import React, { createContext, useReducer,useMemo } from 'react';
import StudentReducer from './Reducer';
import SudentInitialState from './GlobalProvider'
export const GlobalContext: any = createContext({});
const GlobalProvider = ({ children }: any) => {
  
    const [StudentnameState, StudentDispatch] = useReducer(StudentReducer, SudentInitialState)
    const  appStateProvider =useMemo(() => ({ StudentnameState, StudentDispatch}), [ StudentnameState, StudentDispatch ]);

    return (
        <GlobalContext.Provider value={appStateProvider}>
            {children}
        </GlobalContext.Provider>
    )
}

export default GlobalProvider;