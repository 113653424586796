import React from "react";
// Customizable Area Start
import {
    Container,
    Typography,
    Grid,
    Paper,
    Box,
    Tabs,
    Tab,

} from "@material-ui/core";

import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import SustainHeader from "../../../components/src/SustainHeader.web";
import SustainFooter from "../../../components/src/SustainFooter.web";
import CircularProgress from '@material-ui/core/CircularProgress';
import CategoriesCaurosal from "../../../components/src/CategoriesCaurosal.web";
import ProductAndServicesController, { Props, TabPanelProps } from "./ProductAndServicesController.web";
import { err404Img } from "./assets";
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import "./ProductsAndServices.css";

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },

    overrides: {
        MuiContainer: {
            root: {
                paddingLeft: 0,
                paddingRight: 0,
                "@media (min-width: 600px)": {
                    paddingLeft: 0,
                    paddingRight: 0,
                },
            },

        },

    },
});

function TabPanel(props: TabPanelProps) {


    return (
        <div
            role="tabpanel"
            hidden={props.value !== props.index}
            id={`simple-tabpanel-${props.index}`}
            aria-labelledby={`simple-tab-${props.index}`}
        >
            {props.value === props.index && (
                <Box sx={{ p: 3, padding: "0px" }}>
                    <Typography>{props.children}</Typography>
                </Box>
            )}
        </div>
    );
}



function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const CustomTab = withStyles({
    root: {
        backgroundColor: '#fafafa',
        textTransform: "none"
    },
    selected: {
        backgroundColor: '#ffffff',
        color: "#3E8010"
    },
})(Tab);




export default class ProductAndServices extends ProductAndServicesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start 
    // Customizable Area End

    render() {

        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Container maxWidth={false} >

                    <Paper elevation={0} style={webStyle.mainPaperStyle}>
                        {/* ------------------------------------- App bar ---------------------------------------------------- */}
                        <SustainHeader />
                        {/* ------------------------------------- Category corousel ------------------------------- */}


                        {!(this.state.categoryLoader) ?
                            <>

                                <Grid container style={webStyle.gridContainerStyle}>
                                    <Paper elevation={0} style={webStyle.paperVisionStyle}  >
                                        <div style={webStyle.categoryCaruoselStyle}>
                                            <CategoriesCaurosal
                                                category={this.state.productCategory}
                                                subcategoryGetID={this.callApis}
                                            />
                                        </div>
                                    </Paper>
                                </Grid>


                                <Grid container style={{ ...webStyle.gridContainerStyle, marginBottom: "0px" }}>
                                    <Paper elevation={0} style={webStyle.paperVisionStyle}  >
                                        <Box style={{ ...webStyle.searchBoxStyles, float: "right" }}>
                                            <input type="text"
                                                value={this.state.search}
                                                name='search'
                                                placeholder="search"
                                                onChange={this.searchChangeHandler}
                                                onKeyDown={this.handleKeyDown}
                                                style={webStyle.searchInputStyles}
                                                data-testid="searchText"
                                            />
                                            {
                                                this.state.search == "" ? <SearchIcon style={webStyle.searchIconStyles} />
                                                    :
                                                    <ClearIcon style={{ cursor: 'pointer', ...webStyle.searchIconStyles }}
                                                        data-testid="clearText"
                                                        onClick={this.clearSearchHandler}
                                                    />
                                            }

                                        </Box>
                                    </Paper>
                                </Grid>

                                {/* ----------------------------------- Tab ------------------------------ */}
                                <Grid container style={webStyle.gridContainerStyle}>
                                    <Paper elevation={0} style={webStyle.paperVisionStyle}  >
                                        <div style={webStyle.categoryCaruoselStyle}>

                                            <Box sx={{ width: '100%' }}>
                                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                    <Tabs value={this.state.tabValue} onChange={this.handleTabChange} TabIndicatorProps={{ style: { backgroundColor: "#3E8010", height: "0.313vw", borderRadius: "3px" } }} aria-label="basic tabs example">
                                                        <CustomTab label="Product"  {...a11yProps(0)} style={webStyle.TabLabelStyle} />
                                                        <CustomTab label="Service"  {...a11yProps(1)} style={webStyle.TabLabelStyle} />
                                                    </Tabs>
                                                </Box>
                                            </Box>

                                            {this.handleSearchResults() &&
                                                <Typography style={webStyle.searchResultTxt}>Search Results</Typography>
                                            }

                                            {/* -----------------------------------cards ------------------------------ */}
                                            <TabPanel value={this.state.tabValue} index={0}>

                                                <Grid container style={{ ...webStyle.gridContainerStyle, width: "100%", justifyContent: "start" }}>
                                                    <>
                                                        {this.state.productsData?.length > 0 ?
                                                            this.state.productsData.map((product: any) => {
                                                                return (
                                                                    <Grid item xs={4} key={product?.attributes?.id} id="card" style={{ alignItems: 'stretch', ...webStyle.flexGridStyle }}
                                                                        onClick={() => this.ProductCardIDHandler(product?.attributes?.id)}>
                                                                        <Paper elevation={0}
                                                                            style={webStyle.paperCardStyles}
                                                                        >
                                                                            <img id="products"
                                                                                alt="products"
                                                                                src={product?.attributes?.image}
                                                                                style={webStyle.imgStyle1}>
                                                                            </img>
                                                                            <Paper elevation={0} style={{ marginTop: '1.500vw', ...webStyle.paperCardContentStyle }}>
                                                                                <Typography align='left' style={{...webStyle.cardTitle , overflow:"hidden", whiteSpace:"nowrap", textOverflow:"ellipsis"}}>{product?.attributes?.name}</Typography>
                                                                            </Paper>
                                                                            <Paper elevation={0} style={{ height: '3.5vw', ...webStyle.paperCardContentStyle }}>
                                                                                <Typography align='left' style={webStyle.cardContentText}
                                                                                    dangerouslySetInnerHTML={{ __html: product?.attributes?.description }}
                                                                                />
                                                                            </Paper>
                                                                        </Paper>
                                                                    </Grid>
                                                                )
                                                            }
                                                            )
                                                            :
                                                            <>
                                                                {this.handleSearchProduct404Img() ?
                                                                    <Box style={{ justifyContent: "center", display: "grid", width: '100%' }}>
                                                                        <img id="err404Img"
                                                                            alt="Error 404"
                                                                            style={{ width: "31.250vw", height: "21.813vw" }}
                                                                            src={err404Img}
                                                                        >
                                                                        </img>
                                                                        <Typography style={{ ...webStyle.cardContentText, textAlign: "center" }}>Sorry, we couldn’t find any results</Typography>
                                                                    </Box>
                                                                    :
                                                                    <Box style={webStyle.errorMsgStyle}>
                                                                        <Typography style={webStyle.cardContentText}>{this.state.noProductsMsg}</Typography>
                                                                    </Box>
                                                                }
                                                            </>
                                                        }

                                                    </>

                                                </Grid>
                                            </TabPanel>

                                            <TabPanel value={this.state.tabValue} index={1}>
                                                <Grid container style={{ ...webStyle.gridContainerStyle, width: "100%", justifyContent: "start" }}>
                                                    {this.state.servicesData ?
                                                        this.state.servicesData.map((service: any) => {
                                                            return (

                                                                <Grid item xs={4} key={service?.attributes?.id} style={{ alignItems: 'stretch', ...webStyle.flexGridStyle }}
                                                                    onClick={() => this.serviceCardClickHandler(service?.attributes?.id)}
                                                                >
                                                                    <Paper elevation={0}
                                                                        style={webStyle.paperCardStyles}
                                                                    >
                                                                        <img id="services"
                                                                            alt="services"
                                                                            src={service?.attributes?.image}
                                                                            style={webStyle.imgStyle1}>
                                                                        </img>
                                                                        <Paper elevation={0} style={{ marginTop: '1.500vw', ...webStyle.paperCardContentStyle }}>
                                                                            <Typography align='left' style={{...webStyle.cardTitle , overflow:"hidden", whiteSpace:"nowrap", textOverflow:"ellipsis"}}>{service?.attributes?.name}</Typography>
                                                                        </Paper>
                                                                        <Paper elevation={0} style={{ height: '3.5vw', ...webStyle.paperCardContentStyle }}>
                                                                            <Typography align='left' style={webStyle.cardContentText}
                                                                                dangerouslySetInnerHTML={{ __html: service?.attributes?.description }}
                                                                            />
                                                                        </Paper>
                                                                    </Paper>
                                                                </Grid>

                                                            )
                                                        })
                                                        :
                                                        <>
                                                            {this.handleSearchService404Img() ?
                                                                <Box style={{ justifyContent: "center", display: "grid", width: '100%' }}>
                                                                    <img id="err404Img"
                                                                        alt="Error 404"
                                                                        style={{ width: "31.250vw", height: "21.813vw" }}
                                                                        src={err404Img}
                                                                    >
                                                                    </img>
                                                                    <Typography style={{ ...webStyle.cardContentText, textAlign: "center" }}>Sorry, we couldn’t find any results</Typography>
                                                                </Box>
                                                                :
                                                                <Box style={webStyle.errorMsgStyle}>
                                                                    <Typography style={webStyle.cardContentText}>{this.state.noServicesMsg}</Typography>
                                                                </Box>
                                                            }
                                                        </>
                                                    }

                                                </Grid>
                                            </TabPanel>

                                        </div>
                                    </Paper>
                                </Grid>

                            </>
                            :
                            <Box style={webStyle.boxCircularStyle}>
                                <CircularProgress style={{ color: '#3e8010' }} />
                            </Box>
                        }



                        {/* ------------------------------------- footer---------------------------------------------------- */}
                        <Grid container style={webStyle.footerStyle}>
                            {this.state.footer && <SustainFooter footer={this.state.footer} />}
                        </Grid>
                        {/* ------------------------------------- end ---------------------------------------------------- */}
                    </Paper>
                </Container>
            </ThemeProvider >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    searchResultTxt: {
        display: 'flex',
        alignItems: "center",
        color: "#3e8010",
        fontFamily: "DIN2014-DemiBold",
        fontSize: "1.500vw",
        fontWeight: 400,
        marginTop: "1.875vw"
    },
    searchInputStyles: {
        height: '3vw',
        width: '22vw',
        outline: 'none',
        border: 'none',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        fontSize: '1.5vw',
        fontFamily: "DIN2014-DemiBold",
        fontWeight: 400,
        color: '#000',
        padding: '0 1vw 0 0'
    },
    searchIconStyles: {
        height: '2.1vw',
        width: '2vw'
    },
    searchBoxStyles: {
        height: '3.5vw',
        width: '23vw',
        borderRadius: '28px',
        border: '1px solid #e6e6e6',
        backgroundColor: '#ffffff',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 2.5vw',
    },

    TabLabelStyle: {
        width: "6.063vw",
        height: "1.813vw",
        fontFamily: "DIN2014-DemiBold",
        fontSize: "1.500vw",
        fontWeight: 400,
        letterSpacing: "1.11px",
        minHeight: "3vw",
    },
    mainPaperStyle: {
        minHeight: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },

    footerStyle: {
        display: 'flex',
        backgroundColor: '#7BC149',
        minHeight: '23vw',
        padding: "3vw",
        marginTop: 'auto'
    },

    gridContainerStyle: {
        display: 'flex',
        marginTop: '1vw',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '2.5vw',
        width: '96%',
    },
    paperVisionStyle: {
        boxSizing: 'border-box' as 'border-box',
        width: '89%',
    },
    imgStyle1: {
        // width : "24.125vw", 
        // height: '14vw',
        height: '14vw',
        width: '24vw',

    },
    cardTitle: {
        fontFamily: 'DIN2014-DemiBold',
        color: '#3e8010',
        fontWeight: 400,
        fontSize: "1.5vw",
        height: "1.963vw",
        // lineHeight: "29px",
        // fontSize: "24px"
    },

    cardContentText: {
        fontFamily: 'Avenir-Light',
        fontWeight: 400,
        fontSize: '1.25vw',
        // fontSize: '18px',
        color: '#6f6c6c',
        // lineHeight: "30px",
        // letterSpacing: "0.765px"
    },
    categoryCaruoselStyle: {
        height: '100%',
        width: '100%',
    },
    flexGridStyle: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: "2.500vw"
    },
    paperCardStyles: {
        width: '26.8vw',
        height: '24vw',
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#F9F9F9',
        borderRadius: '0.5vw',
        // width: "23.875vw",
        // height: "24.375vw",
        // padding: "24px 22px",
        // background: "#d8d8d8",
    },
    paperCardContentStyle: {
        width: '24vw',
        boxSizing: 'border-box' as 'border-box',
        overflow: 'hidden',
        background: "inherit",
    },

    boxCircularStyle: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '50%',
    },
    errorMsgStyle: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start'
    },
};
// Customizable Area End
