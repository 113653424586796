const StudentReducer = (state: any, { type, payload }: any) => {

    switch (type) {
        case 'UPDATE_NAME':
        return payload
        case 'UPDATE_SURNAME':
        return payload
        
        default:
            return state;
    }

};

export default StudentReducer;