import React, { Component } from "react";

import {
    // Customizable Area Start
   Box,
   Typography,
   Grid,
   IconButton
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import {footerLogo,linkedin,facebook,twitter,instagram,phone,email} from "../../blocks/landingpage/src/assets";
import {Link} from 'react-router-dom';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { GlobalContext } from "./useContext";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },

  overrides: {
    MuiIconButton: {
      root: {
        padding: 0,
      },
      },
   },
});

export default class SustainFooter extends Component<any> {
  static contextType = GlobalContext

  render() {
    const {footer}=this.props;
    return (

      // Customizable Area Start
      <ThemeProvider theme={theme}>
          <Grid item xs={2} style={webStyle.gridItemStyle}>
          <Link to='/'> <img src={footerLogo} style={webStyle.logoFooterStyle} alt="SustainMantra Logo"></img></Link>
          </Grid>
          <Grid item xs={4}  style={webStyle.gridItemStyle}>

          <Box style={{width:'80%', ...webStyle.boxStyle}}>
            <Typography style={webStyle.menuFooterHeaderStyle}>Ready to get started?</Typography>
          <Box  style={webStyle.boxReadyStyle}>
              <Box style={webStyle.boxStyle}>
              <Link to="/ElasticSearch" style={webStyle.menuFooterStyle}>Knowledge Base </Link>
              <Link to="/ProductAndServices" style={webStyle.menuFooterStyle}>Product & Service</Link>
              <Link to="/Contactus" style={webStyle.menuFooterStyle}>  Contact us</Link>
              </Box>
              <Box style={webStyle.boxStyle}>
              <Link to="/WhatWeAre" style={webStyle.menuFooterStyle}>What We Are </Link>
              <Link to="/Privacy" style={webStyle.menuFooterStyle}>Privacy Policy</Link>
              <Link to="/TermsAndConditions" style={webStyle.menuFooterStyle}> Term of service</Link>
              </Box>
              </Box>
          </Box>
          </Grid>
          <Grid item xs={3}  style={webStyle.gridItemStyle}>
          <Box style={{width:'80%', ...webStyle.boxStyle}}>
            <Typography style={webStyle.menuFooterHeaderStyle}>Contact Us</Typography>
          
              <Grid container style={webStyle.boxAddressStyle}>
              <Grid item >
                {
                  footer && <Typography style={webStyle.addressStyle}> 
                  {footer?.address} {footer?.city} - {footer?.pincode} {footer?.country_name}
                  </Typography>
                }
              
              </Grid>
              </Grid> 
                                  
          </Box>
          </Grid>
          <Grid item xs={3}  style={webStyle.gridItemStyle}>
          <Box style={{width:'80%', ...webStyle.boxStyle}}>
            <Typography style={webStyle.menuFooterHeaderStyle}>&nbsp;</Typography>
          
              <Box style={webStyle.boxStyle}>
                <Box style={webStyle.boxIconStyle}>
                  <img src={phone} style={webStyle.iconStyle} alt="Phone icon"></img>
                  {
                    footer && <Typography style={webStyle.menuIconStyle}>{footer.phone_number} </Typography>
                  }
                  
                   
              </Box>
                <Box style={webStyle.boxIconStyle}>
                  <img src={email} style={webStyle.iconStyle} alt="Email icon"></img>
                  {
                    footer && <Typography style={{ cursor:'pointer', ...webStyle.menuIconStyle}} 
                    onClick={()=>{window.location.href =`mailto:${footer.email}`}}> {footer.email}  </Typography>
                  }
                  
                                              
              </Box>
                  {
                    footer && <Box style={{display:'flex'}}>
                    <IconButton >
                        <a  target='_blank' href={footer.twitter_link}><img src={twitter} style={webStyle.socialIconStyle} alt="Twitter icon"></img></a>
                    </IconButton>
                    <IconButton >
                        <a  target='_blank' href={footer.instagram_link}> <img src={instagram} style={webStyle.socialIconStyle} alt="Instagram icon"></img></a>
                      </IconButton>
                    <IconButton >
                       <a  target='_blank' href={footer.facebook_link}> <img src={facebook} style={webStyle.socialIconStyle} alt="Facebook icon"></img></a>
                    </IconButton>
                    <IconButton >
                       <a  target='_blank' href={footer.linkedin_link}> <img src={linkedin} style={webStyle.socialIconStyle} alt="Linkedin icon"></img></a>
                    </IconButton>
                  </Box>
                  }
              
              </Box>
                      
          </Box>
          </Grid>
          <Grid item xs={12} >
          <Typography align="center" style={webStyle.copyrightFooterStyle}>Copyright &#169; 2022. All rights reserved to sustainepedia  </Typography>
          </Grid>
          </ThemeProvider >
      // Customizable Area End
    );
  }

}

// Customizable Area Start
const webStyle = {
  gridItemStyle:{ 
    display:"flex", 
    justifyContent:'center',
    },
  boxStyle:{
    display:'flex',
    flexDirection:'column' as 'column',
    marginTop:'0.438vw'
   },
   boxAddressStyle:{
    display:'flex',
    flexDirection:'column' as 'column',
   },
   boxReadyStyle:{
    display:'flex', 
    justifyContent:'space-between',
     width:'80%'
    },
   boxIconStyle:{
    display:'flex',
     alignItems:'center',
      padding:'0.5vw'
      
    },
    
  logoFooterStyle:{
    height:"13vw",
    width:"13vw",
  },

menuFooterStyle:{
    textDecoration:'none',
    fontFamily:'Avenir-Medium',
    fontWeight: 500,
    fontSize: '1.1vw',
    color:'#FFFFFF',
    marginBottom:'1.2vw',
},
menuIconStyle:{
    textDecoration:'none',
    fontFamily:'Avenir-Medium',
    fontWeight: 500,
    fontSize: '1.1vw',
    color:'#FFFFFF',
   
},
copyrightFooterStyle:{
    fontFamily:'Avenir-Medium',
    fontWeight: 500,
    fontSize: '1.1vw',
    color:'#FFFFFF',
    marginTop:'2.2vw',
},

menuFooterHeaderStyle:{
    textDecoration:'none',
    fontFamily:'Avenir-Heavy',
    fontWeight: 600,
    fontSize: '2.2vw',
    color:'#FFFFFF',
    marginBottom:'2.8vw',
},

iconStyle:{
  width: '1.375vw',
  height: '1.375vw',
  marginRight:'0.5vw',
},
socialIconStyle:{
  width: '3vw',
  height: '3vw',
  padding:'0.3vw'
  },
addressStyle:{
  textDecoration:'none',
  fontFamily:'Avenir-Medium',
  fontWeight: 500,
  fontSize: '1.1vw',
  color:'#FFFFFF',
  lineHeight:'2.8vw',
}
};
// Customizable Area End