import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { GlobalContext } from "../../../components/src/useContext";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  name: string;
  email: string;
  phoneNumber: string;
  comments: string;
  token: string;
  isVisible: boolean;
  inputBorder: string;
  nameIsNull: boolean,
  nameFormatIncorrect: boolean,
  emailIsNull: boolean,
  emailFormatIncorrect:boolean,
  messageIsNull:boolean,
  phoneNumberIsNull: boolean,
  phoneNumberFormatIncorrect: boolean,
  isLoadingMessage: boolean,
  successMessage: boolean,
  errorMessage: boolean,
  contactUsList: any;
  activeId: number;
  activeName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeDescription: string;
  activeCreatedAt: string;
  footer: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ContactusController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  static contextType = GlobalContext
  contactUsApiCallId: any;
  deleteContactApiCallId: any;
  addContactApiCallId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.contactUsApiCallId = "";
    this.deleteContactApiCallId = "";
    this.addContactApiCallId = "";

    this.state = {
      name: "",
      email: "",
      phoneNumber: "",
      comments: "",
      token: "",
      isVisible: false,
      inputBorder: "nameInput",
      nameIsNull: false,
      nameFormatIncorrect: false,
      emailIsNull: false,
      emailFormatIncorrect:false,
      messageIsNull:false,
      phoneNumberIsNull: false,
      phoneNumberFormatIncorrect: false,
      isLoadingMessage: false,
      successMessage: false,
      errorMessage: false,
      contactUsList: [],
      activeId: 0,
      activeName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeDescription: "",
      activeCreatedAt: "",
      footer:{},
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.setState({footer:  await JSON.parse( await getStorageData('footer', false))})
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (this.contactUsApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      runEngine.debugLog("API Message Recived", message);

      if (responseJson && responseJson.data) {
       
          this.setState({ isLoadingMessage: false });
          this.setState({ successMessage: true });

      }  else if (responseJson.errors) {
            this.setState({ isLoadingMessage: false });
            this.setState({ errorMessage: true });
            responseJson.errors.forEach((error: any) => {
              if (error.contact) {
                this.showAlert(configJSON.errorTitle, error.contact.join("."));
              }
            });
        }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  txtNameProps = {
    onChangeText: (text: string) => {
      this.setState({ name: text });

      //@ts-ignore
      this.txtNameProps.value = text;
    },
  };

  txtEmailProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtEmailProps.value = text;
    },
  };
  txtPhoneNumberProps = {
    onChangeText: (text: string) => {
      this.setState({ phoneNumber: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    },
    // keyboardType: "phone-pad"
  };
  txtCommentsProps = {
    multiline: true,
    onChangeText: (text: string) => {
      this.setState({ comments: text });

      //@ts-ignore
      this.txtCommentsProps.value = text;
    },
  };

  errorMessage() {
    return {
      nameErrorMsg: (this.state.nameIsNull && `${configJSON.errorAllFieldsAreMandatory}`) || (this.state.nameFormatIncorrect && `${configJSON.nameNotValidError}`) || " ",
      emailErrorMsg: (this.state.emailIsNull && `${configJSON.errorAllFieldsAreMandatory}`) || (this.state.emailFormatIncorrect && `${configJSON.errorEmailNotValid}`) || " ",
      phoneNoErrorMsg: (this.state.phoneNumberIsNull && `${configJSON.errorAllFieldsAreMandatory}`) || (this.state.phoneNumberFormatIncorrect && `${configJSON.phoneNumberError}`) || " ",
      modalDisplayMessage: (this.state.successMessage ? `${configJSON.apiSuccessResponseMsg}`: `${configJSON.apiErrorResponseMsg}`)
    }
}

  setName = (text: string)=>{
    
  };

  setNameState = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    let nameRegEx = /[^\a-zA-Z\s]/gi;
    if (nameRegEx.test(e.target.value)) {
      this.setState({ nameFormatIncorrect: true }) 
    }else {
      this.setState({nameFormatIncorrect:false})
    }
    this.setState({ name: e.target.value.replace(nameRegEx, "") }, () => {
      if (this.state.name.trim().length > 0) {
        this.setState({ nameIsNull: false });
      }
    })
  };

  setEmail = (text:string)=>{
    
  }

  setEmailState = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    this.setState({ email: e.target.value }, () => {
      if (this.isValidEmail(this.state.email)) {
        this.setState({ emailFormatIncorrect: false });
      }
      if(this.state.email.trim().length > 0){
        this.setState({emailIsNull:false})
      }
    });
  };

  setPhoneNumber = (text: string)=>{
    
  };

  setPhoneNumberState = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const text =
      JSON.stringify(parseInt(e.target.value)) !== "null"
        ? JSON.stringify(parseInt(e.target.value))
        : "";
    this.setState({ phoneNumber: text }, () => {
      if (this.isPhoneNoShort(this.state.phoneNumber)) {
        this.setState({ phoneNumberFormatIncorrect: false });
      }
      else if (this.state.phoneNumber.trim().length > 0) {
        this.setState( { phoneNumberIsNull: false} );
      }
    });
  };

  setComments = (text: string)=>{
    
  };

  setCommentsState = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ comments: e.target.value }, () => {
      if (this.state.comments.trim().length > 0) {
        this.setState({ messageIsNull: false });
      }
    });
  };

  setOutline = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    this.setState({ inputBorder: e.target.id });
  }

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  setModal = (item: any) => {
    
  }

  isStringNullOrBlank(str: string) {
    if (str === null || str.trim().length === 0) {
      return true
    } else {
      return false
    }
  }

  isPhoneNoShort(str: string) {
    return str.trim().length > 9 && str.trim().length < 11;
  }

  isValidEmail = (Email: string) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(Email.trim()) === false) {
      return false;
    } else {
      return true;
    }
  };

  isValidName = (text:string)=>{
    let reg = /^[a-zA-Z][a-zA-Z\s]*$/;
    if(reg.test(text.trim())===false){
      return false;
    } else {
      return true
    }
  }

  setErrorStateOnNullValues = () => {
    if (this.isStringNullOrBlank(this.state.name)) {
      this.setState({
        nameIsNull: true
      })
    }

    if(!this.isValidName(this.state.name)){
      this.setState({nameFormatIncorrect: true});
    }

    if (this.isStringNullOrBlank(this.state.email)) {
      this.setState({
        emailIsNull: true
      })
    }

    if (this.isStringNullOrBlank(this.state.phoneNumber)) {
      this.setState({phoneNumberIsNull: true}
      );   
  }
  if (this.isStringNullOrBlank(this.state.comments)) {
      this.setState({
        messageIsNull: true
      })
    }
     
  }

  addQueryApi = () => {
    if (
      this.isStringNullOrBlank(this.state.name.trim()) ||
      this.isStringNullOrBlank(this.state.email.trim()) ||
      this.isStringNullOrBlank(this.state.phoneNumber.trim()) ||
      this.isStringNullOrBlank(this.state.comments.trim())
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      this.setErrorStateOnNullValues();
      return false;
    } else if (!this.isValidEmail(this.state.email.trim())) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      this.setState({ emailFormatIncorrect: true });
      return false;
    } else if (!this.isPhoneNoShort(this.state.phoneNumber.trim())) {
      this.showAlert(configJSON.errorTitle, configJSON.phoneNumberError);
      this.setState({
        phoneNumberFormatIncorrect: true
      });
      return false;
    } else {
      let data = {
        data: {
          name: this.state.name.trim(),
          email: this.state.email.trim(),
          phone_number: `+91${this.state.phoneNumber.trim()}`,
          message: this.state.comments.trim(),
        },
       
      };
      const header = {
        "Content-Type": configJSON.contactUsApiContentType,
        token: this.state.token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.contactUsApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.postContactUsApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      this.setState({ isLoadingMessage: true });
      this.setState({
        name: "",
        email: "",
        phoneNumber: "",
        comments: "",
        isVisible: true,
        nameIsNull: false,
        nameFormatIncorrect: false,
        emailIsNull: false,
        emailFormatIncorrect:false,
        messageIsNull:false,
        phoneNumberIsNull: false,
      });
      return true;
    }
  };

  btnSubmitProps = {
    onPress: (e:any) => {
      e.preventDefault();
      return this.addQueryApi()
    },
  };

  deleteContactUs = (id: number) => { return true };
  
  addQuery = () => {
    this.props.navigation.navigate("AddContactus");
  };

  // Customizable Area End
}
