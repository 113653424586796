import React from "react";
// Customizable Area Start
import {
    Container,
    Typography,
    Grid,
    Paper,
    Box, Button
} from "@material-ui/core";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import SustainHeader from "../../../components/src/SustainHeader.web";
import SustainFooter from "../../../components/src/SustainFooter.web";
import CircularProgress from '@material-ui/core/CircularProgress';
import ProductAndServicesController, { Props } from "./ProductAndServicesController.web";
import { getStorageData } from "../../../framework/src/Utilities"
import CategoriesCaurosal from "../../../components/src/CategoriesCaurosal.web";
import Slider from "react-slick";

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },

    overrides: {
        MuiContainer: {
            root: {
                paddingLeft: 0,
                paddingRight: 0,
                "@media (min-width: 600px)": {
                    paddingLeft: 0,
                    paddingRight: 0,
                },
            },

        },

    },
});

export const SimiliarCards = (props: any) => {
    const settings = {
        infinite: true,
        variableWidth: true,
        speed: 500,
        slidesToShow:  props.cardData.length > 3 ? 3 : props.cardData.length, 
        slidesToScroll: 1,
        arrow: true
    };    
    return (
        <>

            <Grid container style={{...webStyle.gridVisionDetailStyles , width:"100%"}}>
                <Paper elevation={0} style={{ ...webStyle.paperVisionStyleDetail, width: '100%', }}  >
                    <Grid container style={{ alignItems: 'center', ...webStyle.flexGridStyleDetail, justifyContent: "start" }}>

                        <div style={webStyle.mainSliderStyle}>
                            <Slider {...settings} >
                                {props.cardData?.map((item: any) => {
                                    return (
                                        <Grid item xs={4} key={item?.attributes?.id} style={{ alignItems: 'stretch', ...webStyle.flexGridStyleDetail  }}>
                                            <Paper elevation={0}
                                                id="similarproductCard"
                                                style={webStyle.paperCardStyleDetail}
                                                onClick={() => props.handleOnClickCard(item?.attributes?.id, item?.attributes?.product_category_id)}
                                            >
                                                <img id="similarproductImg"
                                                    alt="similar Product/Services"
                                                    src={item?.attributes?.image}
                                                    style={webStyle.imgStyleCards}>
                                                </img>
                                                <Paper elevation={0} style={{ marginTop: '1.500vw', ...webStyle.paperCardContentDetail }}>
                                                    <Typography align='left' style={{...webStyle.cardHeaderDetail , overflow:"hidden", whiteSpace:"nowrap", textOverflow:"ellipsis"}}>{item?.attributes?.name}</Typography>
                                                </Paper>
                                                <Paper elevation={0} style={{ height: '3.5vw', ...webStyle.paperCardContentDetail }}>
                                                    <Typography align='left' style={webStyle.cardContentDetail}
                                                        dangerouslySetInnerHTML={{ __html: item?.attributes?.description }}
                                                    />
                                                </Paper>
                                                {props.cardProductDetail?.attributes?.selling_price &&
                                                    <Paper elevation={0} style={{ display: "flex", ...webStyle.paperCardContentDetail }}>
                                                        <Typography style={{ marginRight: "0.938vw", color: "#6f6c6c", textDecoration: "line-through", ...webStyle.priceStyle }} >₹{props.cardProductDetail?.attributes?.mrp} </Typography>
                                                        <Typography style={webStyle.priceStyle} >₹{props.cardProductDetail?.attributes?.selling_price}</Typography>
                                                    </Paper>
                                                }
                                            </Paper>

                                        </Grid>
                                    )
                                })}
                            </Slider>
                        </div>
                    </Grid>
                </Paper>
            </Grid>
        </>
    )
}

// Customizable Area End



export default class ProductAndServiceDetailPage extends ProductAndServicesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        this.setState({ footer: await JSON.parse(await getStorageData('footer', false)) })
        this.getProductCategory();
        this.callDetailPageApis();

    }

    // Customizable Area End

    render() {       

        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Container maxWidth={false} >

                    <Paper elevation={0} style={webStyle.mainPaperStyleDetail}>
                        {/* ------------------------------------- App bar ---------------------------------------------------- */}
                        <SustainHeader />
                        {/* ------------------------------------- Category corousel ------------------------------- */}

                        <Grid container style={webStyle.gridVisionDetailStyles}>
                            <Paper elevation={0} style={webStyle.paperVisionStyleDetail}  >
                                <div style={webStyle.categoryDivStyleDetail}>
                                    <CategoriesCaurosal
                                        category={this.state.productCategory}
                                        subcategoryGetID={this.OnClickCarousalDetailPage}
                                        getActiveID={this.state.getActiveID}
                                    />
                                </div>
                            </Paper>
                        </Grid>


                        {!this.state.categoryLoader ?
                            <>
                                {/* ----------------------------------------------------------- */}
                                <Grid container style={webStyle.gridVisionDetailStyles}>
                                    <Paper elevation={0} style={webStyle.paperVisionStyleDetail}  >
                                        <div style={webStyle.categoryDivStyleDetail}>

                                            {/* ----------------------------------- detailed card ------------------------------ */}
                                            {this.state.productDetail ?
                                                
                                                <Grid item style={{ alignItems: 'stretch', ...webStyle.flexGridStyleDetail }} >                                                    
                                                    <Paper elevation={0} style={{ display: "flex" }}>
                                                        <img id="hello"
                                                            alt="Product Detai"
                                                            src={this.state.productDetail?.attributes?.image}
                                                            style={webStyle.imgDetailStyle}>
                                                        </img>
                                                        <Box style={webStyle.TextBox}>
                                                            <Typography style={webStyle.TitleText}>{this.state.productDetail?.attributes?.name}</Typography>
                                                            <Typography style={{...webStyle.BodyText , overflowX:"hidden" , textAlign:"justify"}} dangerouslySetInnerHTML={{ __html: this.state.productDetail?.attributes?.description }} />

                                                            {this.state.productDetail?.attributes?.selling_price &&
                                                                <Paper elevation={0} style={{ display: "flex", ...webStyle.paperCardContentDetail , marginTop:"1.250vw" }}>
                                                                    <Typography style={{ marginRight: "15px", color: "#6f6c6c", textDecoration: "line-through", ...webStyle.priceStyle }} >₹{this.state.productDetail?.attributes?.mrp} </Typography>
                                                                    <Typography style={webStyle.priceStyle} >₹{this.state.productDetail?.attributes?.selling_price}</Typography>
                                                                </Paper>
                                                            }
                                                            <Button id="submitBtnId1" variant="contained" style={webStyle.BtnStyle} onClick={() => this.knowMoreBtnHandler()} >
                                                                <Typography style={{...webStyle.KnowMoreStyles,  textAlign: "center" , textTransform:"capitalize"}}>Know more</Typography>
                                                            </Button>
                                                        </Box>

                                                    </Paper>
                                                </Grid>

                                                :
                                                <Box style={webStyle.BoxCircularDetail}>
                                                    <CircularProgress style={{ color: '#3e8010' }} />
                                                </Box>
                                            }


                                            {/* -----------------------------------  similar cards  ------------------------------ */}

                                            <SimiliarCards
                                                cardData={this.state.similarProduct}
                                                handleOnClickCard={this.similarProductsClickHandler}
                                                cardProductDetail={this.state.productDetail}
                                            />

                                            <SimiliarCards
                                                cardData={this.state.similarServices}
                                                handleOnClickCard={this.similarServicesClickHandler}
                                                cardProductDetail={this.state.productDetail}
                                            />
                                       
                                        </div>
                                    </Paper>
                                </Grid>

                            </>
                            :
                            <Box style={webStyle.BoxCircularDetail}>
                                <CircularProgress style={{ color: '#3e8010' }} />
                            </Box>
                        }



                        {/* ------------------------------------- footer---------------------------------------------------- */}
                        <Grid container style={webStyle.footerStyleDetail}>
                            {this.state.footer && <SustainFooter footer={this.state.footer} />}
                        </Grid>
                        {/* ------------------------------------- end ---------------------------------------------------- */}
                    </Paper>
                </Container>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    mainSliderStyle: {
        width: '100%',
        height: '100%',
    },
    priceStyle: {
        fontFamily: "PingFangSC-Light",
        fontSize: "1.125vw",
        fontWeight: 400
    },
    TextBox: {
        width: "53.125vw",
        height: "19.938vw",
        marginLeft: "2.500vw",
    },
    TitleText: {
        // width: "13.188vw",
        height: "1.813vw",
        color: "#3e8010",
        fontFamily: "DIN2014-DemiBold",
        fontSize: "1.500vw",
        fontWeight: 400,
        letterSpacing: "0.069vw"
    },
    BodyText: {
        marginTop: "0.625vw",
        color: "#6f6c6c",
        fontFamily: "Avenir-Light",
        fontSize: "1.125vw",
        fontWeight: 400,
        // lineHeight: "2.188vw",
        // letterSpacing: "0.048vw",
        height: "16vw",
        cursor: "pointer",
        paddingRight : "1.875vw"
    },
    BtnStyle: {
        width: "12.500vw",
        height: "3.750vw",
        backgroundColor: "#7BC149",
        borderRadius: "3.125vw",
        color: "#FFFFFF",
        marginTop: "1.5vw",
    },
    KnowMoreStyles: {
        // height: "1.5vw",
        color: "#ffffff",
        fontSize: "1.250vw",
        fontFamily: "Avenir-Medium",
        fontWeight: 400,       
    },

    mainPaperStyleDetail: {
        minHeight: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },

    footerStyleDetail: {
        display: 'flex',
        backgroundColor: '#7BC149',
        minHeight: '23vw',
        padding: "3vw",
        marginTop: 'auto'
    },

    gridVisionDetailStyles: {
        display: 'flex',
        marginTop: '1vw',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '2.5vw',
        width: '96%',
    },
    paperVisionStyleDetail: {
        boxSizing: 'border-box' as 'border-box',
        width: '89%',
    },

    imgDetailStyle: {
        width: "25.5vw",
        height: "22vw",
        padding: "2.68vw 1.5vw",
        // background: "#d8d8d8",
        borderRadius: "10px",
        backgroundColor: '#F9F9F9',

    },
    imgStyleCards: {
        // // width: "23.875vw",
        // width : "24.125vw", 
        height: '14vw',
        width: '24vw',

    },
    cardHeaderDetail: {
        fontFamily: 'DIN2014-DemiBold',
        color: '#3e8010',
        fontWeight: 400,
        fontSize: "1.5vw",
        height: "1.963vw",
        // lineHeight: "29px",
        // fontSize: "24px"
    },

    cardContentDetail: {
        fontFamily: 'Avenir-Light',
        fontWeight: 400,
        fontSize: '1.25vw',
        // fontSize: '18px',
        color: '#6f6c6c',
        // lineHeight: "30px",
        // letterSpacing: "0.765px"
    },

    categoryDivStyleDetail: {
        height: '100%',
        width: '100%',
    },
    flexGridStyleDetail: {
        display: 'flex',
        justifyContent: 'center',
        // justifyContent: 'flex-start'
    },
    paperCardStyleDetail: {
        width: '26.8vw',
        height: '24vw',
        marginTop: "3vw",
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#F9F9F9',
        marginRight:"1.875vw"
        // width: "23.875vw",
        // height: "24.375vw",
        // padding : "1.500vw",
        // padding: "24px 22px",
        // background: "#d8d8d8",
    },
    paperCardContentDetail: {
        width: '24vw',
        boxSizing: 'border-box' as 'border-box',
        overflow: 'hidden',
        background: "inherit",
    },
    BoxCircularDetail: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '50%',
    }
};
// Customizable Area End
