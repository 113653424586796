import React from "react";

import {
    // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";


// Customizable Area Start
import Slider from "react-slick";


const SustainTeam = ({ team}:any) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: team.length <=3 ? team.length : 3,
    slidesToScroll: 1
  };
  return (

    // Customizable Area Start
    <>
 {team.length > 0 &&
  <Slider {...settings}>
 {
               team.map((item:any, index:number)=>{
                  return(
                   <div style={{  margin:' auto'}} key={item?.attributes?.id}>
                       <div style={{marginTop:index % 2 == 0 ? '8vw': '16vw'}}>
                            <img src={item?.attributes?.image?.url} style={webStyle.teamStyle} alt="Team member"></img>
                            <p  style={{textAlign: 'center', ...webStyle.teamHeader}}>{item?.attributes?.name}</p>
                            <p style={{textAlign: 'center', ...webStyle.teamSubHeader}}> {item?.attributes?.role} </p>
                        </div>
                     </div>
                  )
                })
  }
  </Slider>
}

    </>
  );
}

// Customizable Area Start
const webStyle = {
  teamStyle:{
    width: '15.7vw',
    height: '14.6vw',
    margin:' auto'
  },
  teamHeader:{
    color: '#00a7e2',
    fontFamily: "Avenir-Medium",
    fontWeight: 400,
    letterSpacing: '0.15vw',
    fontSize:'1.5vw',
    margin:'1vw 0 0 0 ',
    // marginTop:'1vw',
   },
  teamSubHeader:{
    color: '#6f6c6c',
    fontFamily: "DIN2014-DemiBold",
    fontWeight: 400,
    letterSpacing: '0.15vw',
    fontSize:'1.12vw',
    margin:0,
  },
};

export default SustainTeam;
// Customizable Area End
