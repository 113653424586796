Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";

exports.categoryApiContentType = "application/json";
exports.categoryAPIEndPoint = "categories/categories";
exports.subCategoryAPIEndPoint = "categories/sub_categories";

exports.errorTitle = "Error"
exports.errorAllFieldsAreMandatory = "Please enter a category first"
exports.errorCategory = "Please select a category first"

exports.contentTypeApi = "application/json";
exports.apiGetMethodType = "GET";

exports.productsApiCallUrl = "bx_block_categories/products"
exports.productCategoryApiCallUrl = "bx_block_categories/product_categories"
exports.productDetailApiCallUrl = "bx_block_categories/products/"
exports.similarProductsAPiCallUrl = "bx_block_categories/similar_products"

exports.servicesApiCallUrl = "bx_block_categories/services"
exports.serviceDetailApiCallUrl = "bx_block_categories/services/"
exports.similarServicesApiCallUrl = "bx_block_categories/similar_services"


// Customizable Area End