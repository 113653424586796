import React, {useEffect, useRef} from "react";

import {
    // Customizable Area Start
 Grid,
 Paper,
 Typography,
  // Customizable Area End
} from "@material-ui/core";


// Customizable Area Start
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const SustainCircleAnimation = ({  collaboration,
  commerce,
  commitment,
  communication,
  globeImg,
  roundArrow}:any) => {

    const globeRef = useRef(null);
    const smallCircle1Ref = useRef(null);
    const smallCircle2Ref = useRef(null);
    const smallCircle3Ref = useRef(null);
    const bigCircle1Ref = useRef(null);
    const bigCircle2Ref = useRef(null);
    const bigCircle3Ref = useRef(null);
    const bigCircle4Ref = useRef(null);
  
   useEffect(()=>{
   const elGlobe=globeRef.current;
   const elSmallCircle1 = smallCircle1Ref.current;
   const elSmallCircle2 = smallCircle2Ref.current;
   const elSmallCircle3 = smallCircle3Ref.current;
   const elBigCircle1 = bigCircle1Ref.current;
   const elBigCircle2 = bigCircle2Ref.current;
   const elBigCircle3 = bigCircle3Ref.current;
   const elBigCircle4 = bigCircle4Ref.current;

   //  ---------------------------- Circle 1 ----------------------------------------
   gsap.to(elBigCircle1,  {opacity:1, duration:2, scrollTrigger:{
    trigger: elBigCircle1
   }})
   gsap.to(elSmallCircle1, {opacity:1, duration:2, scrollTrigger:{
    trigger: elBigCircle1
   }})
   gsap.fromTo(elGlobe, {rotation:0}, {rotation:15, duration:3, scrollTrigger:{
    trigger: elBigCircle1
   }})
  //  ---------------------------- Circle 2 ----------------------------------------
   gsap.to(elBigCircle2,  {opacity:1, duration:2, delay :3, scrollTrigger:{
    trigger: elBigCircle2
   }})
   gsap.to(elSmallCircle2, {opacity:1, duration:2, delay :3, scrollTrigger:{
    trigger: elBigCircle2
   }})
   gsap.fromTo(elGlobe, {rotation:15}, {rotation:30, delay :3, duration:3, scrollTrigger:{
    trigger: elBigCircle2
   }})

  //  ---------------------------- Circle 3 ----------------------------------------
   gsap.to(elBigCircle3,  {opacity:1, duration:2, delay :6, scrollTrigger:{
    trigger: elBigCircle3
   }})
   gsap.to(elSmallCircle3, {opacity:1, duration:2, delay :6, scrollTrigger:{
    trigger: elBigCircle3
   }})
   gsap.fromTo(elGlobe, {rotation:30}, {rotation:45, delay :6, duration:3, scrollTrigger:{
    trigger: elBigCircle3
   }})
  //  ---------------------------- Circle 4 ----------------------------------------
   gsap.to(elBigCircle4,  {opacity:1, duration:2, delay :9, scrollTrigger:{
    trigger: elBigCircle4
   }})
   gsap.fromTo(elGlobe, {rotation:45}, {rotation:60, delay :9, duration:3, scrollTrigger:{
    trigger: elBigCircle4
   }})
  },[]);

 
  return (

    // Customizable Area Start
    <>
     <Paper elevation={0} style={{ boxSizing: 'border-box',width:'80%', display:'flex',  alignItems:'center', justifyContent:'center'}}  >
            <Grid item xs={2} ref={bigCircle1Ref}
             style={webStyle.circleGrid} >
            <img src={collaboration} style={webStyle.bigCirleStyle} alt="Globe animation"></img>
            <Typography style={webStyle.circleText}>Collaboration</Typography>
            </Grid>
            <Grid item xs={1} ref={smallCircle1Ref}
             style={webStyle.smallCircleGrid} >
            <img src={roundArrow} style={webStyle.smallCirleStyle} alt="Globe animation"></img>
            </Grid>
             <Grid item xs={2} ref={bigCircle2Ref}
              style={webStyle.circleGrid} >
            <img src={commitment} style={webStyle.bigCirleStyle} alt="Globe animation"></img>
            <Typography style={webStyle.circleText}>Commitment</Typography>
            </Grid>
            <Grid item xs={1} ref={smallCircle2Ref}
             style={webStyle.smallCircleGrid} >
            <img src={roundArrow} style={webStyle.smallCirleStyle} alt="Globe animation"></img>
            </Grid>
             <Grid item xs={2} ref={bigCircle3Ref}
            style={webStyle.circleGrid} >
            <img src={communication} style={webStyle.bigCirleStyle} alt="Globe animation"></img>
            <Typography style={webStyle.circleText}>Communication</Typography>
            </Grid>
            <Grid item xs={1} ref={smallCircle3Ref}
             style={webStyle.smallCircleGrid} >
            <img src={roundArrow} style={webStyle.smallCirleStyle} alt="Globe animation"></img>
            </Grid>
             <Grid item xs={2} ref={bigCircle4Ref}
              style={webStyle.circleGrid} >
            <img src={commerce} style={webStyle.bigCirleStyle} alt="Globe animation"></img>
            <Typography style={webStyle.circleText}>Commerce</Typography>
            </Grid>
            </Paper>

            <Paper elevation={0} style={{ marginTop:'4.5vw', zIndex:97, boxSizing: 'border-box', display:'flex',  alignItems:'center', justifyContent:'center'}}  >
            <Grid item xs={12} >
            <img src={globeImg} style={webStyle.globeStyle} ref={globeRef} alt="Globe animation"></img>
            </Grid>
            </Paper>
    </>
  );
}

// Customizable Area Start
const webStyle = {
  bigCirleStyle:{
    width: '9.3vw',
    height: '9.3vw',
  },
  smallCirleStyle:{
    width: '1.4vw',
    height: '1.4vw',
  },
  globeStyle:{
    width: '75vw',
    height: '62.5vw',
  },
  circleGrid:{
   display:'flex',
   alignItems:'center', 
   justifyContent:'center',
   flexDirection:'column' as 'column',
   opacity:0,
  },
  circleText:{
   marginTop:'1vw',
   color: '#000',
   fontFamily: "DIN2014-DemiBold",
   fontWeight: 400,
   fontSize:'1.5vw'
  },
  smallCircleGrid:{
    display:'flex',
    alignItems:'center', 
    justifyContent:'center',
    opacity:0,
  }
};

export default SustainCircleAnimation;
// Customizable Area End
