import React from "react";

import {
  // Customizable Area Start
  Box,
  IconButton,
  AppBar,
  Toolbar,
  Menu,
  Container,
  MenuItem,

  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'react-router-dom';
import { logo } from "../../blocks/landingpage/src/assets";
const pages = [
  { name: "Home", url: "/", detailUrl: "/WhatWeAre" , detailUrl1: "/TermsAndConditions", detailUrl2: "/Privacy"},
  { name: "Knowledge Base", url: "/ElasticSearch", detailUrl: "/InfoPage" },
  { name: "Product & Service", url: "/ProductAndServices" ,  detailUrl:"/ProductAndServiceDetailPage" },
  { name: "Contact us", url: "/Contactus" },
];

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiPaper: {
      elevation4: {
        boxShadow: 'none'
      },
    },
    MuiMenuItem: {
      root: {
        minHeight: 0
      },
    },
  },
});


const SustainHeader = () => {
  let urlElements = window.location.href.split('/')
  let activeLink = '/' + urlElements[3];
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (

    // Customizable Area Start
    <>
      <ThemeProvider theme={theme}>
        <AppBar position="static" >
          <Container maxWidth={false}>
            <Toolbar disableGutters >

              <Box sx={{ flexGrow: 1, ml: "3vw", display: { xs: 'none', sm: 'flex' } }}>

                <Link to='/'><img src={logo} style={webStyle.logoStyle} alt="SustainMantra Logo"></img></Link>
              </Box>

              <Box sx={{ mr: "3vw", display: { xs: 'none', sm: 'flex', } }}>

                {pages.map((page) => (
                  <Link
                    key={page.name}
                    to={page.url}
                    style={{
                      marginTop: 2,
                      marginBottom: 2,
                      marginLeft: "auto",
                      display: 'block',
                      fontFamily: 'Avenir-Medium',
                      fontWeight: 500,
                      fontSize: '1.25vw',
                      color: activeLink == page.url 
                      || (page.detailUrl && activeLink == page.detailUrl) 
                      || (page.detailUrl1 && activeLink == page.detailUrl1) 
                      || (page.detailUrl2 && activeLink == page.detailUrl2) 
                      ? "#78C044" : "#535353",
                      padding: '1vw',
                      textDecoration: "none",
                    }}
                  >
                    {page.name}
                  </Link>
                ))}
              </Box>

              {/* --------------------------------------Hamburger menu ---------------------------------------------------    */}
              <Box sx={{ display: { xs: 'flex', sm: 'none' }, flexGrow: 1 }}>
              <Link to='/'> <img src={logo}  style={{ height:'55px', width:'55px'}} alt="SustainMantra Logo"></img></Link> 
              </Box>

              <Box sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'none' }, alignItems:'flex-end', justifyContent:'flex-end' }}>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon style={{ color: '#000', fontSize: '20px' }} />
                </IconButton>
                <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}

                  >
                    {pages.map((page) => (
                      <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                        <Link
                          to={page.url}
                          style={{
                            fontFamily: 'Avenir-Medium',
                            fontSize: '10px',
                            textDecoration: "none",
                            padding: 0,
                            color: activeLink == page.url 
                            || (page.detailUrl && activeLink == page.detailUrl) 
                            || (page.detailUrl1 && activeLink == page.detailUrl1) 
                            || (page.detailUrl2 && activeLink == page.detailUrl2) 
                            ? "#78C044" : "#535353",
                          }}

                        >
                          {page.name}
                        </Link>
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              </Box>

              {/* --------------------------------------------------------------------------------------------------------------      */}

            </Toolbar>
          </Container>
        </AppBar>
      </ThemeProvider>
    </>
  );
}

// Customizable Area Start
const webStyle = {
  logoStyle: {
    height: "9vw",
    width: "9vw",
  },
};

export default SustainHeader;
// Customizable Area End
