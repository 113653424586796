import React, {useEffect, useRef} from "react";

import {
    // Customizable Area Start
 Grid,
 Paper,
 Typography,
 Button,
 Box
  // Customizable Area End
} from "@material-ui/core";


// Customizable Area Start

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import {  textlabel1, textlabel2, textlabel3,textlabel4, LINE, } from "../../blocks/landingpage/src/assets";
gsap.registerPlugin(ScrollTrigger);

const SustainLeafAnimation = ({  
  leafup,
  leafdown,
  bluedot,
  landing_WhatWeAre,
  btnWhatWeAre
  }:any) => {

    const lineRef = useRef(null);

    const oneDotRef = useRef(null);
    const oneRef = useRef(null);
    const oneHeadRef = useRef(null);
    const oneTextRef = useRef(null);

    const twoDotRef = useRef(null);
    const twoRef = useRef(null);
    const twoHeadRef = useRef(null);
    const twoTextRef = useRef(null);
    const twoLeafDownRef = useRef(null);

    
    const threeDotRef = useRef(null);
    const threeRef = useRef(null);
    const threeHeadRef = useRef(null);
    const threeTextRef = useRef(null);
    const threeLeafUpRef = useRef(null);


    const fourDotRef = useRef(null);
    const fourRef = useRef(null);
    const fourHeadRef = useRef(null);
    const fourTextRef = useRef(null);
    const fourLeafUpRef = useRef(null);
    const fourLeafDownRef = useRef(null);

  
   useEffect(()=>{
   const elLine=lineRef.current;

   const elOneDot = oneDotRef.current;
   const elOne = oneRef.current;
   const elOneHead = oneHeadRef.current;
   const elOneText = oneTextRef.current;

   const elTwoDot = twoDotRef.current;
   const elTwo = twoRef.current;
   const elTwoHead = twoHeadRef.current;
   const elTwoText = twoTextRef.current;
   const elTwoLeafDown = twoLeafDownRef.current;


   const elThreeDot = threeDotRef.current;
   const elThree = threeRef.current;
   const elThreeHead = threeHeadRef.current;
   const elThreeText = threeTextRef.current;
   const elThreeLeafUp = threeLeafUpRef.current;


   const elFourDot = fourDotRef.current;
   const elFour = fourRef.current;
   const elFourHead = fourHeadRef.current;
   const elFourText = fourTextRef.current;
   const elFourLeafUp = fourLeafUpRef.current;
   const elFourLeafDown = fourLeafDownRef.current;
   

   //  ---------------------------- Line  ----------------------------------------
   gsap.to(elLine,  {opacity:1, duration:3,  scrollTrigger:{
    trigger: elLine
   }})

  //  ---------------------------- One ----------------------------------------
   gsap.to(elOneDot,  {opacity:1, duration:2, delay :2, scrollTrigger:{
    trigger: elLine
   }})
   gsap.to(elOne,  {opacity:1, duration:2, delay :2, scrollTrigger:{
    trigger: elLine
   }})
   gsap.to(elOneHead,  {opacity:1, duration:2, delay :2, scrollTrigger:{
    trigger: elLine
   }})
   gsap.to(elOneText,  {opacity:1, duration:2, delay :2, scrollTrigger:{
    trigger: elLine
   }})
 
  //  ---------------------------- Two ----------------------------------------
  gsap.to(elTwoDot,  {opacity:1, duration:2, delay :4, scrollTrigger:{
    trigger: elLine
   }})
   gsap.to(elTwo,  {opacity:1, duration:2, delay :4, scrollTrigger:{
    trigger: elLine
   }})
   gsap.to(elTwoHead,  {opacity:1, duration:2, delay :4, scrollTrigger:{
    trigger: elLine
   }})
   gsap.to(elTwoText,  {opacity:1, duration:2, delay :4, scrollTrigger:{
    trigger: elLine
   }})
   gsap.to(elTwoLeafDown,  {opacity:1, duration:2, delay :4, scrollTrigger:{
    trigger: elLine
   }})
  //  ---------------------------- Three ----------------------------------------
  gsap.to(elThreeDot,  {opacity:1, duration:2, delay :6, scrollTrigger:{
    trigger: elLine
   }})
   gsap.to(elThree,  {opacity:1, duration:2, delay :6, scrollTrigger:{
    trigger: elLine
   }})
   gsap.to(elThreeHead,  {opacity:1, duration:2, delay :6, scrollTrigger:{
    trigger: elLine
   }})
   gsap.to(elThreeText,  {opacity:1, duration:2, delay :6, scrollTrigger:{
    trigger: elLine
   }})
   gsap.to(elThreeLeafUp,  {opacity:1, duration:2, delay :6, scrollTrigger:{
    trigger: elLine
   }})
  //  ---------------------------- Four ----------------------------------------
  gsap.to(elFourDot,  {opacity:1, duration:2, delay :8, scrollTrigger:{
    trigger: elLine
   }})
   gsap.to(elFour,  {opacity:1, duration:2, delay :8, scrollTrigger:{
    trigger: elLine
   }})
   gsap.to(elFourHead,  {opacity:1, duration:2, delay :8, scrollTrigger:{
    trigger: elLine
   }})
   gsap.to(elFourText,  {opacity:1, duration:2, delay :8, scrollTrigger:{
    trigger: elLine
   }})
   gsap.to(elFourLeafDown,  {opacity:1, duration:2, delay :8, scrollTrigger:{
    trigger: elLine
   }})
   gsap.to(elFourLeafUp,  {opacity:1, duration:2, delay :8, scrollTrigger:{
    trigger: elLine
   }})

 
  },[]);

 
  return (

    // Customizable Area Start
    <>
        <Paper elevation={0} style={{ boxSizing: 'border-box', ...webStyle.paperContainerLine }} ref={lineRef} >
        
        <Grid style={{display:'flex', flexDirection:'column' }}>
        <Paper elevation={0} style={webStyle.paperContainerLeafText}>
            <Typography align="left" style={{  width:'100%', ...webStyle.blockTitle}} >SUSTAINPEDIA </Typography>
        
            <Typography align="left" style={{  width:'100%',...webStyle.blockHeader}}>{landing_WhatWeAre.title} </Typography>
            <Paper elevation={0} style={webStyle.paperContainerLeafSubText}>
           
            <Typography   align="left" style={{  width:'100%', ...webStyle.blockContent}} 
                             dangerouslySetInnerHTML={{ __html: landing_WhatWeAre.description }} />
        </Paper>
            <Button variant="contained"  style={{textAlign:'center',  textTransform:'none', ...webStyle.btnLearn }}
            onClick={btnWhatWeAre}
            > Read More </Button>
       </Paper>
        </Grid>
            {/* -------------------------------------- Number 1  --------------------------------------------- */}
        <Grid >
        <img src={bluedot} style={{  marginTop:'9.8vw', marginLeft:'10.6vw', ...webStyle.bluedotStyle}} ref={oneDotRef} alt="Leaf animation"></img>
        <Paper elevation={0} style={{ boxSizing: 'border-box', ...webStyle.paperContainerOne}} >
          <Box style={webStyle.numberBoxStyle}>
          <Typography align="left" style={webStyle.lineHeader} ref={oneHeadRef}> No Poverty </Typography>
          <img src={textlabel1} style={webStyle.imgNumberStyle}  ref={oneRef} alt="Leaf animation"></img>
          </Box>
          <Typography align="left" style={webStyle.dashedContentText} ref={oneTextRef}>We will strive to make planet Earth</Typography>
        </Paper>
        </Grid>
            {/* -------------------------------------- Number 2  --------------------------------------------- */}
        <Grid >
    
        <img src={bluedot} style={{  marginTop:'-15vw', marginLeft:'34.3vw', ...webStyle.bluedotStyle}} ref={twoDotRef} alt="Leaf animation"></img>
        <Paper elevation={0} style={{ boxSizing: 'border-box', ...webStyle.paperContainerTwo}}  >
        <Box style={webStyle.numberBoxStyle}>
          <Typography align="left" style={webStyle.lineHeader} ref={twoHeadRef}> Zero Hunger </Typography>
          <img src={textlabel2} style={webStyle.imgNumber2Style}  ref={twoRef} alt="Leaf animation"></img>
        </Box>
           <Typography align="left" style={webStyle.dashedContentText} ref={twoTextRef}>That strive to take less and give back more</Typography>
        </Paper>
        <img src={leafdown} style={{  marginTop:'-8.25vw', marginLeft:'21.8vw', ...webStyle.leafStyle}} ref={twoLeafDownRef} alt="Leaf animation"></img>
        </Grid>
            {/* -------------------------------------- Number 3  --------------------------------------------- */}
        <Grid >
        <img src={bluedot} style={{  marginTop:'-20vw', marginLeft:'58.5vw', ...webStyle.bluedotStyle}} ref={threeDotRef} alt="Leaf animation"></img>
        <Paper elevation={0} style={{ boxSizing: 'border-box', ...webStyle.paperContainerThree}}>
        <Box style={webStyle.numberBoxStyle}>
          <Typography align="left" style={webStyle.lineHeader} ref={threeHeadRef}> Quality Educaion </Typography>
          <img src={textlabel3} style={webStyle.imgNumber3Style}  ref={threeRef} alt="Leaf animation"></img>
        </Box>
           <Typography align="left" style={webStyle.dashedContentText} ref={threeTextRef}>That strive to take less and give back more</Typography>
          <img src={leafup} style={{  marginTop:'-27.8vw', marginLeft:'-16vw', ...webStyle.leafStyle}} ref={threeLeafUpRef} alt="Leaf animation"></img>
        </Paper>
        </Grid>
            {/* -------------------------------------- Number 4  --------------------------------------------- */}
        <Grid >
        <img src={bluedot} style={{  marginTop:'-46.2vw', marginLeft:'75vw', ...webStyle.bluedotStyle}}  ref={fourDotRef} alt="Leaf animation"></img>
        <Paper elevation={0} style={{ boxSizing: 'border-box', ...webStyle.paperContainerFour}}  >
        <Box style={webStyle.numberBoxStyle}>
        <Typography align="left" style={webStyle.lineHeader} ref={fourHeadRef}> Gender Equality</Typography>
          <img src={textlabel4} style={webStyle.imgNumber4Style}  ref={fourRef} alt="Leaf animation"></img>
        </Box>
          
          <Typography align="left" style={webStyle.dashedContentText} ref={fourTextRef}>That strive to take less and give back more</Typography>
          <img src={leafup} style={{  marginTop:'-16.8vw', marginLeft:'-25vw', ...webStyle.leafStyle}} ref={fourLeafUpRef} alt="Leaf animation"></img>
          <img src={leafdown} style={{  marginTop:'1.8vw', marginLeft:'6vw', ...webStyle.leafStyle}} ref={fourLeafDownRef} alt="Leaf animation"></img>
        </Paper>
        </Grid>
       </Paper>
    </>
  );
}

// Customizable Area Start
const webStyle = {
  paperContainerLine: {
    display:'flex', 
   flexDirection:'column' as 'column',
   backgroundImage: `url(${LINE})`,
   backgroundSize: 'cover',
   backgroundPosition: 'center',
   height:"38vw",
   width:"90vw",
   opacity:0,
 },
 
 leafStyle:{
   width:'2.4vw',
   height:'3.1vw',
   opacity:0,
 },
 bluedotStyle:{
   width:'3.75vw',
   height:'3.75vw',
   opacity:0,
 },
 paperContainerOne: {
   marginTop:'1.5vw',
   marginLeft:'3.75vw',
   display:'flex', 
   flexDirection:'column' as 'column',
   height:"9vw",
   width:"14vw",
   padding:'1.5vw',
 },

 paperContainerTwo: {
   marginTop:'-11.2vw',
   marginLeft:'30vw',
   display:'flex', 
   flexDirection:'column' as 'column',
   height:"9vw",
   width:"16vw",
   padding:'1.5vw',
   },

 paperContainerThree: {
   marginTop:'-14vw',
   marginLeft:'60vw',
   display:'flex', 
   flexDirection:'column' as 'column',
   height:"9vw",
   width:"18vw",
   padding:'1.5vw',

 },
 paperContainerFour: {
   marginTop:'-41vw',
   marginLeft:'75vw',
   display:'flex', 
   flexDirection:'column' as 'column',
   height:"9vw",
   width:"18vw",
   padding:'1.5vw',
 },
 blockTitle:{
  color: '#000',
  fontFamily: "DIN2014-DemiBold",
  fontWeight: 400,
  fontSize:'1.25vw'
},
blockHeader:{
  color: '#3e8010',
  fontFamily: "BCAlphapipeTSB-Bold",
  fontWeight: 400,
  letterSpacing: '0.15vw',
  fontSize:'3vw'
},
blockContent:{
  color: '#000',
  fontFamily: "Avenir-Light",
  fontWeight: 400,
  fontSize:'1.25vw',

},
dashedContentText:{
  color: '#6f6c6c',
  fontFamily: "Avenir-Light",
  fontWeight: 400,
  fontSize:'1.12vw',
  opacity:0,

},
lineHeader:{
  color: '#3e8010',
  fontFamily: "DIN2014-DemiBold",
  fontWeight: 400,
  fontSize:'1.5vw',
  opacity:0,
},
btnLearn :{
  height: "3.7vw",
  width: '12vw',
  borderRadius: "1.8vw",
  fontSize: "1.25vw",
  marginTop:'1vw',
  fontFamily: "Avenir-Medium",
  color: "#fafafb",
  fontWeight: 400,
  backgroundColor: "#7BC149",
},
paperContainerLeafText:{ 
  boxSizing: 'border-box' as 'border-box', 
  height:'22.5vw', 
  width:'40.625vw'
},
paperContainerLeafSubText:{ 
  boxSizing: 'border-box' as 'border-box', 
  height:'12.2vw', 
  width:'40.625vw', 
  overflow:'hidden',
},
numberBoxStyle:{
  display:'flex',
  alignItems:'flex-end',
},
imgNumberStyle:{
  height:'5.625vw', 
  width:'3.75vw', 
  opacity:0
},
imgNumber2Style:{
  height:'4.375vw', 
  width:'3.75vw', 
  opacity:0
},
imgNumber3Style:{
  height:'4.375vw', 
  width:'3.75vw', 
  opacity:0
},
imgNumber4Style:{
  height:'4.375vw', 
  width:'3.75vw', 
  opacity:0
},
};

export default SustainLeafAnimation;
// Customizable Area End
