export const Bitmap = require("../assets/Bitmap.png");
export const logo = require("../assets/logo.png");
export const LINE = require("../assets/LINE.png");
export const sampleVideo = require("../assets/sampleVideo.mp4");
export const collaboration = require("../assets/collaboration.png");
export const commerce = require("../assets/commerce.png");
export const commitment = require("../assets/commitment.png");
export const communication = require("../assets/communication.png");
export const globeImg = require("../assets/globeImg.png");
export const roundArrow = require("../assets/roundArrow.png");
export const teamearth = require("../assets/teamearth.png");
export const textlabel1 = require("../assets/textlabel1.png");
export const textlabel2 = require("../assets/textlabel2.png");
export const textlabel3 = require("../assets/textlabel3.png");
export const textlabel4 = require("../assets/textlabel4.png");
export const leafup = require("../assets/leafup.png");
export const leafdown = require("../assets/leafdown.png");
export const bluedot = require("../assets/bluedot.png");
export const phone = require("../assets/phone.png");
export const email = require("../assets/email.png");
export const instagram = require("../assets/instagram.png");
export const twitter = require("../assets/twitter.png");
export const linkedin = require("../assets/linkedin.png");
export const facebook = require("../assets/facebook.png");
export const footerLogo = require("../assets/footerLogo.png");
export const greenleaf1 = require("../assets/greenleaf1.png");
export const greenleaf2 = require("../assets/greenleaf2.png");
export const greenleafCenter1 = require("../assets/greenleafCenter1.png");
export const greenleafCenter2 = require("../assets/greenleafCenter2.png");
export const yellowleaf1 = require("../assets/yellowleaf1.png");
export const yellowleaf2 = require("../assets/yellowleaf2.png");
export const yellowleaf3 = require("../assets/yellowleaf3.png");
export const yellowleafBig = require("../assets/yellowleafBig.png");
export const yellowleafBig1 = require("../assets/yellowleafBig1.png");
export const yellowleafBig2 = require("../assets/yellowleafBig2.png");
export const yellowGroup = require("../assets/yellowGroup.png");