Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start


exports.contentTypeApi = "application/json";
exports.apiGetMethodType = "GET";
exports.blogByIdCallUrl ='blogs/';
exports.recentBlogsCallUrl = 'reccent_blogs';
exports.recentBlogsTitle = 'Recent posts';
exports.videoBlogTypeText ='Video';
exports.knowledgeBaseText = 'Knowledge Base'

// Customizable Area End