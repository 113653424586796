import React from "react";
// Customizable Area Start
import {
  Container,
  Box,
  Typography,
  Grid,
  Paper,
  Button
} from "@material-ui/core";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  collaboration,
  commerce,
  commitment,
  communication,
  globeImg,
  roundArrow,
  teamearth,
  leafup,
  leafdown,
  bluedot,
   } from './assets';
import SustainHeader from "../../../components/src/SustainHeader.web";
import SustainFooter from "../../../components/src/SustainFooter.web";
import SustainCircleAnimation from "../../../components/src/SustainCircleAnimation.web";
import SustainLeafAnimation from "../../../components/src/SustainLeafAnimation.web";
import SustainVideo from "../../../components/src/SustainVideo.web";
import SustainTeam from "../../../components/src/SustainTeam.web";


const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },

  overrides: {
    MuiContainer: {
      root: {
        paddingLeft: 0,
        paddingRight:0,
        "@media (min-width: 600px)": {
          paddingLeft: 0,
          paddingRight:0,
        },
      },
      
    },
    
  },
});
// Customizable Area End

import LandingPageController, {
  Props,
} from "./LandingPageController";

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      <ThemeProvider theme={theme}>
      <Container maxWidth={false} >
    <Paper elevation={0} style={webStyle.mainPaperStyle}>
        {/* ------------------------------------- App bar ---------------------------------------------------- */}
       <SustainHeader/>
      {/* ------------------------------------- video banner---------------------------------------------------- */}
        <Grid container style={webStyle.gridVideo}>
            <SustainVideo/>
        </Grid>
        {/* ------------------------------------- what we are -- line---------------------------------------------------- */}
        <Grid container style={{display:'flex',alignItems:'center', justifyContent:'center'}}>
        <SustainLeafAnimation  leafup={leafup} 
                               leafdown={leafdown} 
                               bluedot={bluedot} 
                               landing_WhatWeAre={this.state.landing_WhatWeAre}
                               btnWhatWeAre = {this.btnWhatWeAre}
                               />
        </Grid>

      {/* ------------------------------------- vision and mission---------------------------------------------------- */}
         <Grid container style={webStyle.gridVision}>
            <Paper elevation={0} style={webStyle.paperVisionStyle}  >
                <Typography align='center' style={webStyle.blockTitle} >SUSTAINPEDIA </Typography>
                <Typography align='center' style={ webStyle.blockHeader}>{this.state.landing_VisionMission.title} </Typography>
                <Typography  align='center' style={webStyle.blockContent} 
                             dangerouslySetInnerHTML={{ __html: this.state.landing_VisionMission.description }} />
            </Paper>
          </Grid>
           {/* ------------------------------------- Circle animation---------------------------------------------------- */}
         <Grid container style={webStyle.gridCircle}>
          <SustainCircleAnimation 
            collaboration={collaboration}
            commerce={commerce}
            commitment={commitment}
            communication={communication}
            globeImg={globeImg}
            roundArrow={roundArrow}
        />
            
          {/* ------------------------------------- sustainpedia services ---------------------------------------------------- */}
            <Paper elevation={0} style={webStyle.gridServices}  >
            <Grid item xs={12}>
              <Box style={webStyle.gridMainBoxServices}>
                     <Grid container style={webStyle.gridMainContainerServices}>
                          <Grid item xs={6} style={webStyle.gridItemServices}>
                             <Paper elevation={0} style={webStyle.paperServices} >
                                  <Typography  style={ webStyle.blockBigHeader}>{this.state.landing_Services.title} </Typography>
                                  <Paper elevation={0} style={webStyle.paperServicesText} >
                                  <Typography  style={{marginBottom:'1vw', ...webStyle.blockContent}} 
                                               dangerouslySetInnerHTML={{ __html: this.state.landing_Services.description }} />
                                  </Paper>
                                  <Button variant="contained" style={{textAlign:'center', textTransform:'none', ...webStyle.btnLearn }} onClick={this.btnService}> View All </Button>
                            </Paper>
                          </Grid>

                          <Grid item xs={6} style={webStyle.dashedBoxGrid}>
                            <Box style={webStyle.dashedBoxStyle}>
                             
                              <Box style={webStyle.dashedBox}>
                                <Typography style={webStyle.dashedContentHeader}>{this.state.servicesData?.[0]?.attributes?.name}</Typography>
                                <Typography style={{...webStyle.dashedContentText , overflow:"hidden" }} 
                                 dangerouslySetInnerHTML={{ __html: this.state.servicesData?.[0]?.attributes?.description }}
                                />
                              </Box>

                              <Box style={webStyle.dashedVertical}></Box>

                              <Box style={webStyle.dashedBox}>
                                 <Typography style={webStyle.dashedContentHeader}>{this.state.servicesData?.[1]?.attributes?.name}</Typography>
                                 <Typography style={{...webStyle.dashedContentText, overflow:"hidden", }} 
                                 dangerouslySetInnerHTML={{ __html: this.state.servicesData?.[1]?.attributes?.description }}
                                />                                
                              </Box>
                              
                              </Box>
                              <Box style={webStyle.dashedHorizontal}></Box>

                            <Box style={webStyle.dashedBoxStyle}>
                              <Box style={webStyle.dashedBox}>
                                 <Typography style={webStyle.dashedContentHeader}>{this.state.servicesData?.[2]?.attributes?.name}</Typography>
                                 <Typography style={{...webStyle.dashedContentText , overflow:"hidden", }} 
                                 dangerouslySetInnerHTML={{ __html: this.state.servicesData?.[2]?.attributes?.description }}
                                />                                
                              </Box>
                              <Box style={webStyle.dashedVertical}></Box>
                              <Box style={webStyle.dashedBox}>
                                 <Typography style={webStyle.dashedContentHeader}>{this.state.servicesData?.[3]?.attributes?.name}</Typography>
                                 <Typography style={{...webStyle.dashedContentText , overflow:"hidden",}} 
                                 dangerouslySetInnerHTML={{ __html: this.state.servicesData?.[3]?.attributes?.description }}
                                />                               
                              </Box>
                            </Box>
                          </Grid>
                     </Grid>       
              </Box>
              </Grid>
            </Paper>
         </Grid>
        {/* ------------------------------------- Team ---------------------------------------------------- */}
        <Grid container style={webStyle.gridTeam}>
             <Paper elevation={0} style={webStyle.paperContainerEarth}  >
               <Typography align='center' style={webStyle.blockTitle} >SUSTAINPEDIA </Typography>
                <Typography align='center' style={ webStyle.blockHeader}>Our Team </Typography>
              <div style={webStyle.gridContainerTeam}>
              <SustainTeam team= {this.state.team}/>
              </div>
               </Paper>
          </Grid>
          
        {/* ------------------------------------- footer---------------------------------------------------- */}
        <Grid container style={webStyle.footerStyle}>
        <SustainFooter footer={this.state.footer}/>
        </Grid>
        {/* ------------------------------------- end ---------------------------------------------------- */}
        </Paper>
      </Container>
      
    </ThemeProvider >
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainPaperStyle:{ 
    minHeight: '100vh',
    width:'100vw'
  },
  gridVideo:{
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    marginBottom:'4.5vw'
  },
  boxVideo:{
    position:'relative' as 'relative',
    display:'flex',
    flexDirection:'column' as 'column',
    alignItems:'center',
    justifyContent:'center',
    width:'90%',
  },
  boxVideoTextStyle:{
    position:'absolute' as 'absolute',
    display:'flex',
    flexDirection:'column' as 'column',
    alignItems:'center',
    justifyContent:'center',
    width:'50%',
    },
  gridVision:{
    display:'flex',  
    alignItems:'center', 
    justifyContent:'center',
    marginTop:'14vw', 
    marginBottom:'4.5vw',
  },
  paperVisionStyle:{ 
    boxSizing: 'border-box' as 'border-box',
    width:'65%'
  },
  gridCircle:{
    display:'flex', 
    alignItems:'center',
    justifyContent:'center',
    marginTop:'5.5vw',
    marginBottom:'4.5vw'
    },
    gridServices:{
      width:'100%',
      zIndex:98, marginTop:'-40vw',
      boxSizing: 'border-box' as 'border-box',
      display:'flex', 
      alignItems:'center',
      justifyContent:'center'
    },
    gridItemServices:{
      paddingRight:'1vw',
      display:'flex', 
      alignItems:'center', 
      justifyContent:'center',
      flexDirection:'column' as 'column',
    },
    gridMainBoxServices:{
      height:'42.5vw',
      backgroundColor:'#f6fbf2'
    },
    gridMainContainerServices:{
      padding:'10vw'
    },
    paperServices:{ 
      boxSizing: 'border-box' as 'border-box',
      backgroundColor:'#f6fbf2',
      width:'39vw',
      height:'27vw'
    },
    paperServicesText:{ 
      boxSizing: 'border-box' as 'border-box',
      backgroundColor:'#f6fbf2',
      width:'39vw',
      height:'6.5vw',
      overflow:'hidden',
    },
    dashedBoxStyle:{
      display:'flex', 
      alignItems:'center',
      justifyContent:'center'
    },
    gridTeam:{
      display:'flex',
      flexDirection:'column' as 'column', 
      alignItems:'center', 
      justifyContent:'center',
      marginTop:'4.5vw'
    },
    gridContainerTeam:{ 
      height:'100%', 
      width:'100%'
    },

footerStyle:{
  display:'flex',
  backgroundColor:'#7BC149',
  minHeight:'23vw',
  padding:"3vw",
},

btnLearn :{
  height: "3.7vw",
  width: '12vw',
  borderRadius: "1.8vw",
  fontSize: "1.25vw",
  marginTop:'1vw',
  fontFamily: "Avenir-Medium",
  color: "#fafafb",
  fontWeight: 400,
  backgroundColor: "#7BC149",
},

blockTitle:{
  color: '#000',
  fontFamily: "DIN2014-DemiBold",
  fontWeight: 400,
  fontSize:'1.25vw'
},
blockBigHeader:{
  color: '#3e8010',
  fontFamily: "BCAlphapipeTSB-Bold",
  fontWeight: 400,
  letterSpacing: '0.1vw',
  fontSize:'5vw'
},
blockHeader:{
  color: '#3e8010',
  fontFamily: "BCAlphapipeTSB-Bold",
  fontWeight: 400,
  letterSpacing: '0.15vw',
  fontSize:'3vw'
},
blockContent:{
  color: '#000',
  fontFamily: "Avenir-Light",
  fontWeight: 400,
  fontSize:'1.25vw'
},

dashedBoxGrid:{
  paddingLeft:'1vw',
  display:'flex', 
   alignItems:'center', 
   justifyContent:'center',
   flexDirection:'column' as 'column'
  },
  dashedBox:{
    height:"11vw",
    width:'15vw',
    display:'flex', 
    alignItems:'center',
    justifyContent:'center',
    flexDirection:'column' as 'column',
   },
  dashedHorizontal: {
    width:'28.5vw',
    height:'1px',
    backgroundPosition: 'bottom',
    backgroundImage:'linear-gradient(to right, #979797 50%, rgba(255,255,255,0) 0%)',
    backgroundSize:'0.8vw 1px',
    backgroundRepeat:'repeat-x',
},
  dashedVertical: {
    height:'10.5vw',
    width:'1px',
    backgroundPosition: 'right',
    backgroundImage:'linear-gradient(#979797 50%, rgba(255,255,255,0) 0%)',
    backgroundSize:'1px 0.8vw',
    backgroundRepeat:'repeat-y',
},
  dashedContentHeader:{
    color: '#00a7e2',
    fontFamily: "DIN2014-DemiBold",
    fontWeight: 400,
    letterSpacing: '0.15vw',
    fontSize:'1.5vw',
    width:'70%',
  },
  dashedContentText:{
    color: '#6f6c6c',
    fontFamily: "Avenir-Light",
    fontWeight: 400,
    fontSize:'1.12vw',
    width:'70%',
    height : "2.750vw"
  },
  teamStyle:{
    width: '15.7vw',
    height: '14.6vw',
  },
  teamHeader:{
    color: '#00a7e2',
    fontFamily: "Avenir-Medium",
    fontWeight: 400,
    letterSpacing: '0.15vw',
    fontSize:'1.5vw',
    marginTop:'1vw',
  },
  teamSubHeader:{
    color: '#6f6c6c',
    fontFamily: "DIN2014-DemiBold",
    fontWeight: 400,
    letterSpacing: '0.15vw',
    fontSize:'1.12vw',
  },
paperContainerEarth:{
    display:'flex', 
    flexDirection:'column' as 'column',
    backgroundImage: `url(${teamearth})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height:"50vw",
    width:"85vw",
},

};
// Customizable Area End
