Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ElasticSearch";
exports.labelBodyText = "ElasticSearch Body";

exports.btnExampleTitle = "CLICK ME";
exports.errorMsg = "Sorry, we couldn’t find any results";

exports.contentTypeApi = "application/json";
exports.apiGetMethodType = "GET";

exports.categoryApiCallUrl="bx_block_categories/categories";
exports.subCategoryApiCallUrl="blogs?category_id=";
exports.searchApiCallUrl="blogs?search=";

// Customizable Area End