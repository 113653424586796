import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import rssParser from "react-native-rss-parser";
import { Linking } from "react-native";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  rssData: any;
  data: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RSSFeedsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      data: null,
      rssData: `<?xml version="1.0" encoding="utf-8" ?> <rss version="2.0" xml:base="http://www.nasa.gov/" xmlns:atom="http://www.w3.org/2005/Atom" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:itunes="http://www.itunes.com/dtds/podcast-1.0.dtd" xmlns:media="http://search.yahoo.com/mrss/"> <channel> <title>NASA Breaking News</title>
      <description>A RSS news feed containing the latest NASA news articles and press releases.</description>
      <link>http://www.nasa.gov/</link>
      <atom:link rel="self" href="http://www.nasa.gov/rss/dyn/breaking_news.rss" />
      <language>en-us</language>
      <managingEditor>jim.wilson@nasa.gov</managingEditor>
      <webMaster>brian.dunbar@nasa.gov</webMaster>
      <docs>http://blogs.harvard.edu/tech/rss</docs>
      <item> <title>Media Invited to see NASA Mega Moon Rocket Roll Out for First Time</title>
      <link>http://www.nasa.gov/press-release/media-invited-to-see-nasa-mega-moon-rocket-roll-out-for-first-time</link>
      <description>For a limited time, NASA has reopened media registration to capture images and video of the agency's Space Launch System (SLS) rocket and Orion spacecraft rolling out for the first time.</description>
      <enclosure url="http://www.nasa.gov/sites/default/files/styles/1x1_cardfeed/public/thumbnails/image/ksc-20210917-ph-fmx01_0068_large.jpg?itok=olIbG8jI" length="331258" type="image/jpeg" />
      <guid isPermaLink="false">http://www.nasa.gov/press-release/media-invited-to-see-nasa-mega-moon-rocket-roll-out-for-first-time</guid>
      <pubDate>Tue, 11 Jan 2022 16:55 EST</pubDate>
      <source url="http://www.nasa.gov/rss/dyn/breaking_news.rss">NASA Breaking News</source>
      <dc:identifier>476586</dc:identifier>
     </item>
      <item> <title>NASA&#039;s Aerospace Safety Advisory Panel Releases 2021 Annual Report</title>
      <link>http://www.nasa.gov/press-release/nasas-aerospace-safety-advisory-panel-releases-2021-annual-report</link>
      <description>The Aerospace Safety Advisory Panel (ASAP), an advisory committee that reports to NASA and Congress, issued its 2021 annual report Tuesday examining the agency's safety performance over the past year and highlighting accomplishments, issues, and concerns.</description>
      <enclosure url="http://www.nasa.gov/sites/default/files/styles/1x1_cardfeed/public/thumbnails/image/2021_asap_report-tagged_002-01.png?itok=D7mcARi1" length="1344846" type="image/png" />
      <guid isPermaLink="false">http://www.nasa.gov/press-release/nasas-aerospace-safety-advisory-panel-releases-2021-annual-report</guid>
      <pubDate>Tue, 11 Jan 2022 15:52 EST</pubDate>
      <source url="http://www.nasa.gov/rss/dyn/breaking_news.rss">NASA Breaking News</source>
      <dc:identifier>476577</dc:identifier>
     </item>
      <item> <title>Air Force Cadets to Hear from NASA Astronaut Aboard Space Station</title>
      <link>http://www.nasa.gov/press-release/air-force-cadets-to-hear-from-nasa-astronaut-aboard-space-station</link>
      <description>Cadets from the U.S. Air Force Academy will have an opportunity this week to hear from a NASA astronaut aboard the International Space Station.</description>
      <enclosure url="http://www.nasa.gov/sites/default/files/styles/1x1_cardfeed/public/thumbnails/image/chari02.jpg?itok=A2_3eqi6" length="66586" type="image/jpeg" />
      <guid isPermaLink="false">http://www.nasa.gov/press-release/air-force-cadets-to-hear-from-nasa-astronaut-aboard-space-station</guid>
      <pubDate>Tue, 11 Jan 2022 14:26 EST</pubDate>
      <source url="http://www.nasa.gov/rss/dyn/breaking_news.rss">NASA Breaking News</source>
      <dc:identifier>476565</dc:identifier>
     </item>
      <item> <title>NASA, NOAA to Announce 2021 Global Temperatures, Climate Conditions</title>
      <link>http://www.nasa.gov/press-release/nasa-noaa-to-announce-2021-global-temperatures-climate-conditions</link>
      <description>Climate researchers from NASA and the National Oceanic and Atmospheric Administration (NOAA) will release their annual assessments of global temperatures and discuss the major climate trends of 2021 during a media teleconference at 11 a.m. EST Thursday, Jan. 13.</description>
      <enclosure url="http://www.nasa.gov/sites/default/files/styles/1x1_cardfeed/public/thumbnails/image/print_cel2020_00000.jpg?itok=i4lc42Ya" length="4675496" type="image/jpeg" />
      <guid isPermaLink="false">http://www.nasa.gov/press-release/nasa-noaa-to-announce-2021-global-temperatures-climate-conditions</guid>
      <pubDate>Tue, 11 Jan 2022 10:44 EST</pubDate>
      <source url="http://www.nasa.gov/rss/dyn/breaking_news.rss">NASA Breaking News</source>
      <dc:identifier>476542</dc:identifier>
     </item>
     </channel>
     </rss>`,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const data = await rssParser.parse(this.state.rssData);
    this.setState({ data });
    // Customizable Area End
  }

  // Customizable Area Start
  openUrl = (url: string) => {
    Linking.canOpenURL(url).then(() => {
      Linking.openURL(url);
    });
  };
  // Customizable Area End
}
