import React from "react";
// Customizable Area Start
import {
  Container,
  Typography,
  Grid,
  Paper,
} from "@material-ui/core";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import SustainHeader from "../../../components/src/SustainHeader.web";
import SustainFooter from "../../../components/src/SustainFooter.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },

  overrides: {
    MuiContainer: {
      root: {
        paddingLeft: 0,
        paddingRight:0,
        "@media (min-width: 600px)": {
          paddingLeft: 0,
          paddingRight:0,
        },
      },
      
    },
    
  },
});
// Customizable Area End

import LandingPageController, {
  Props,
} from "./LandingPageController";

export default class WhatWeAre extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
             <Container maxWidth={false} >
    <Paper elevation={0} style={webStyle.mainPaperStyleWe}>
        {/* ------------------------------------- App bar ---------------------------------------------------- */}
       <SustainHeader/>
      {/* ------------------------------------- Content terms and conditions ------------------------------- */}
      <Grid container style={webStyle.gridVisionWe}>
            <Paper elevation={0} style={webStyle.paperVisionStyleWe}  >
                <Typography  style={webStyle.blockTitleWe} >SUSTAINPEDIA </Typography>
                <Typography  style={ webStyle.blockHeaderWe}>
                  {this.state.whatWeAre.title} 
                  </Typography>
                <Typography   style={webStyle.blockContentWe} 
                            dangerouslySetInnerHTML={{ __html: this.state.whatWeAre.description }} /> 
            </Paper>
          </Grid>
          
        {/* ------------------------------------- footer---------------------------------------------------- */}
        <Grid container style={webStyle.footerStyleWe}> 
        {this.state.footer &&  <SustainFooter footer={this.state.footer} /> }
        </Grid>
        {/* ------------------------------------- end ---------------------------------------------------- */}
        </Paper>
      </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainPaperStyleWe:{ 
    minHeight: '100vh',
    width:'100vw',
    display:'flex',
     flexDirection:'column' as 'column',
  },

footerStyleWe:{
  display:'flex',
  backgroundColor:'#7BC149',
  minHeight:'23vw',
  padding:"3vw",
  marginTop:'auto'
},

gridVisionWe:{
  display:'flex',  
  marginTop:'4.5vw', 
  alignItems:'center', 
  justifyContent:'center',
  marginBottom:'4.5vw',
},
paperVisionStyleWe:{ 
  boxSizing: 'border-box' as 'border-box',
  width:'89%'
},

blockTitleWe:{
  color: '#000',
  fontFamily: "DIN2014-DemiBold",
  fontWeight: 400,
  fontSize:'1.25vw'
},
blockBigHeaderWe:{
  color: '#3e8010',
  fontFamily: "BCAlphapipeTSB-Bold",
  fontWeight: 400,
  letterSpacing: '0.1vw',
  fontSize:'5vw'
},
blockHeaderWe:{
  fontWeight: 400,
  letterSpacing: '0.15vw',
  color: '#3e8010',
  fontFamily: "BCAlphapipeTSB-Bold",
  fontSize:'3vw'
},
blockContentWe:{
  fontWeight: 400,
  fontSize:'1.25vw',
  color: '#000',
  fontFamily: "Avenir-Light",

},


};
// Customizable Area End
