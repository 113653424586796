// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';

import InfoPage from '../../blocks/info-page/src/InfoPage.web'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import BlogImporting from '../../blocks/BlogImporting/src/BlogImporting';
import RSSFeeds from '../../blocks/rssfeeds/src/RSSFeeds';
import NavigationMenu from '../../blocks/navigationmenu/src/NavigationMenu';
import LinkShare from '../../blocks/LinkShare/src/LinkShare';
import Settings5 from '../../blocks/Settings5/src/Settings5';
import BulkUploading from '../../blocks/BulkUploading/src/BulkUploading';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import TermsAndConditions from '../../blocks/TermsAndConditions/src/TermsAndConditions.web';
import Privacy from '../../blocks/TermsAndConditions/src/Privacy.web';
import RolesPermissions from '../../blocks/RolesPermissions/src/RolesPermissions';
import AdminConsole from '../../blocks/AdminConsole/src/AdminConsole';
import Contactus from '../../blocks/contactus/src/ContactUsForm.web';
import AddContactus from '../../blocks/contactus/src/AddContactus';
import SearchEngineOptimisationseo from '../../blocks/SearchEngineOptimisationseo/src/SearchEngineOptimisationseo';
import ElasticSearch from '../../blocks/ElasticSearch/src/ElasticSearch.web';
import ProductAndServices from '../../blocks/categoriessubcategories/src/ProductAndServices.web';
import ProductAndServiceDetailPage from '../../blocks/categoriessubcategories/src/ProductAndServiceDetailPage.web';
import LandingPage from '../../blocks/landingpage/src/LandingPage.web';
import WhatWeAre from '../../blocks/landingpage/src/WhatWeAre.web';

import GlobalProvider from '../../components/src/useContext';

const routeMap = {
  BlogImporting: {
    component: BlogImporting,
    path: '/BlogImporting'
  },
  RSSFeeds: {
    component: RSSFeeds,
    path: '/RSSFeeds'
  },
  NavigationMenu: {
    component: NavigationMenu,
    path: '/NavigationMenu'
  },
  LinkShare: {
    component: LinkShare,
    path: '/LinkShare'
  },
  Settings5: {
    component: Settings5,
    path: '/Settings5'
  },
  BulkUploading: {
    component: BulkUploading,
    path: '/BulkUploading'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  TermsAndConditions: {
    component: TermsAndConditions,
    path: '/TermsAndConditions'
  },
  Privacy: {
    component: Privacy,
    path: '/Privacy'
  },
  RolesPermissions: {
    component: RolesPermissions,
    path: '/RolesPermissions'
  },
  AdminConsole: {
    component: AdminConsole,
    path: '/AdminConsole'
  },
  Contactus: {
    component: Contactus,
    path: '/Contactus'
  },
  AddContactus: {
    component: AddContactus,
    path: '/AddContactus'
  },
  SearchEngineOptimisationseo: {
    component: SearchEngineOptimisationseo,
    path: '/SearchEngineOptimisationseo'
  },
  ElasticSearch: {
    component: ElasticSearch,
    path: '/ElasticSearch'
  },
  ProductAndServices:{
    component :ProductAndServices,
    path: '/ProductAndServices'
  },
  ProductAndServiceDetailPage:{
    component :ProductAndServiceDetailPage,
    path: '/ProductAndServiceDetailPage'
  },
  LandingPage: {
    component: LandingPage,
    path: '/LandingPage'
  },
  WhatWeAre: {
    component: WhatWeAre,
    path: '/WhatWeAre'
  },

  Home: {
    component: LandingPage,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  }
  
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends Component {

  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <GlobalProvider>
        <View style={{ height: '100vh', width: '100vw' }}>
          {/* <TopNav /> */}
          {WebRoutesGenerator({ routeMap })}
          {/* <ModalContainer /> */}
        </View>
      </GlobalProvider>
    );
  }
}

export default App;