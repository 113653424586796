import React from "react";
// Customizable Area Start
import {
  Container,
  Typography,
  Grid,
  Paper,
  Box,
  Breadcrumbs,
  Card,
  CardMedia
} from "@material-ui/core";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import SustainHeader from "../../../components/src/SustainHeader.web";
import SustainFooter from "../../../components/src/SustainFooter.web";
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Slider from "react-slick";
export const configJSON = require("./config");

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },

  overrides: {
    MuiContainer: {
      root: {
        paddingLeft: 0,
        paddingRight: 0,
        "@media (min-width: 600px)": {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },

    },
    MuiPaper: {
      rounded: {
        borderRadius: 0,
      },
      root: {
        backgroundColor: 'transparent',
        boxShadow: 'none'
      },
    }

  },
});
// Customizable Area End

import InfoPageController, {
  Props,
} from "./InfoPageController";

export default class InfoPage extends InfoPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: this.state.recentBlogs.length <=3 ? this.state.recentBlogs.length : 3,
      slidesToScroll: 1,
      arrow: true,
      variableWidth: true
    };
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={false} >
          <Paper elevation={0} style={webStyle.mainPaperStyleWe}>
            {/* ------------------------------------- App bar ---------------------------------------------------- */}
            <SustainHeader />
            {/* ------------------------------------- Info Page ---------------------------------------------------- */}
            {/* ------------------------------------- Back Icon ---------------------------------------------------- */}
            <Grid container style={webStyle.backGridVisionblogInfoStyle}>
              <Paper elevation={0} style={webStyle.paperVisionblogInfoStyle}>
                <ArrowBackIosIcon style={webStyle.backIconBoxStyle} onClick={this.backArrowBtnHandler} />
              </Paper>
            </Grid>
            {/* ------------------------------------- end ---------------------------------------------------- */}
            {this.state.infoPageLoader ?
              <Box style={webStyle.circularProgressBoxStyle}>
                <CircularProgress style={{ color: '#3e8010' }} />
              </Box>
              :
              <Grid container style={webStyle.gridVisionblogInfoStyle}>
                <Paper elevation={0} style={webStyle.paperVisionblogInfoStyle}>
                  {/* ------------------------------------- Breadcrumbs ---------------------------------------------------- */}
                  <Breadcrumbs separator={<NavigateNextIcon style={webStyle.navigateNextIconStyle} />} aria-label="breadcrumb">
                    <Typography style={webStyle.activeblogBreadCrumbText}>
                      {configJSON.knowledgeBaseText}
                    </Typography>
                    <Typography style={webStyle.activeblogBreadCrumbText}>
                      {this.state.infoblogData?.attributes?.blog_type}
                    </Typography>
                    <Typography style={webStyle.activeblogCateogaryText}>
                      {this.state.infoblogData?.attributes?.category}
                    </Typography>
                  </Breadcrumbs>
                  {/* ------------------------------------- end ---------------------------------------------------- */}
                  <Typography style={webStyle.blogTitleTextStyle}>
                    {this.state.infoblogData?.attributes?.title}
                  </Typography>
                  {this.state.isIFrameLoaded && this.state.infoblogData.attributes?.blog_type === configJSON.videoBlogTypeText && this.state.infoblogData?.attributes?.video_link != null &&
                    <iframe src={this.state.infoblogData?.attributes?.video_link} style={webStyle.iFrameBoxStyle} ></iframe>
                  }
                  {this.state.isIFrameLoaded && this.state.infoblogData.attributes?.blog_type === 'Link' && this.state.infoblogData?.attributes?.link != null &&
                    <iframe src={this.state.infoblogData?.attributes?.link} style={webStyle.iFrameBoxStyle} ></iframe>
                  }
                  <Typography style={webStyle.recentBlogDescriptionStyle} dangerouslySetInnerHTML={{ __html: this.state.infoblogData?.attributes?.description }} />
                </Paper>
              </Grid>
            }
            <Grid container style={webStyle.recentGridVisionblogInfoStyle}>
              <Paper elevation={0} style={webStyle.paperVisionblogInfoStyle}>
                <Typography style={webStyle.recentPostsTextStyle}>
                  {configJSON.recentBlogsTitle}
                </Typography>
                {this.state.recentBlogs.length > 0 &&
                  <div style={webStyle.mainSliderCardStyle}>
                    <Slider {...settings} >
                      {this.state.recentBlogs.map((_recentBlogData: any, index: number) => (
                        <Box onClick={() => this.recentBlogsInfoById(_recentBlogData.id, window)} key={_recentBlogData.id}>
                          <Card style={webStyle.mainCardStyle}>
                            <Grid container spacing={1}>
                              <Grid item xs={3}>
                                <CardMedia
                                  style={{
                                    objectFit: 'contain',
                                    ...webStyle.imgStyle,
                                  }}
                                  image={_recentBlogData?.attributes?.image}
                                />
                              </Grid>
                              <Grid item xs={9}>
                                <Box style={webStyle.mainCardContentStyle}>
                                  <Box style={webStyle.gridTitle}>
                                  <Typography style={webStyle.recentBlogTitleStyle}>
                                    {_recentBlogData?.attributes?.title}
                                  </Typography>
                                  </Box>
                                  <Box>
                                    <Typography style={{
                                      display: '-webkit-box',
                                      WebkitBoxOrient: 'vertical',
                                      WebkitLineClamp: 2,
                                      overflow: "hidden",
                                      height: "2.45vw",
                                      ...webStyle.recentBlogDescriptionStyle,
                                    }} dangerouslySetInnerHTML={{ __html: _recentBlogData?.attributes?.description }} />
                                  </Box>
                                  <Typography style={webStyle.recentBlogDateStyle}>
                                    {_recentBlogData?.attributes?.created_at}
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </Card>
                        </Box>
                      ))}
                    </Slider>
                  </div>
                }
              </Paper>
            </Grid>
            {/* ------------------------------------- end---------------------------------------------------- */}

            {/* ------------------------------------- footer---------------------------------------------------- */}
            <Grid container style={webStyle.footerStyleInfo}>
              {this.state.footer && <SustainFooter footer={this.state.footer} />}
            </Grid>
            {/* ------------------------------------- end ---------------------------------------------------- */}
          </Paper>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainPaperStyleWe: {
    minHeight: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  footerStyleInfo: {
    display: 'flex',
    backgroundColor: '#7BC149',
    minHeight: '23vw',
    padding: "3vw",
    marginTop: 'auto'
  },
  gridVisionblogInfoStyle: {
    display: 'flex',
    marginTop: '2.5vw',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '2.5vw',
  },
  recentGridVisionblogInfoStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '4.5vw',
  },
  backGridVisionblogInfoStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paperVisionblogInfoStyle: {
    boxSizing: 'border-box' as 'border-box',
    width: '89%'
  },
  blogTitleTextStyle: {
    fontWeight: 400,
    letterSpacing: '0.15vw',
    color: '#3e8010',
    fontFamily: "BCAlphapipeTSB-Bold",
    fontSize: '2.5vw',
    margin: '1vw 0vw'
  },
  activeblogBreadCrumbText: {
    color: '#000000',
    fontFamily: 'Avenir-Medium',
    fontSize: '1vw',
  },
  activeblogCateogaryText: {
    color: '#3e8010',
    fontFamily: 'Avenir-Medium',
    fontSize: '1vw'
  },
  circularProgressBoxStyle: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50%',
  },
  cardContent: {
    fontFamily: 'Avenir-Light',
    fontWeight: 400,
    fontSize: '1.25vw',
    color: 'red',
    backgroundColor: 'transparent'
  },
  recentPostsTextStyle: {
    fontWeight: 400,
    letterSpacing: '0.15vw',
    color: '#3e8010',
    fontFamily: "BCAlphapipeTSB-Bold",
    fontSize: '2.5vw'
  },
  imgStyle: {
    height: '6vw',
    width: '6vw',
   },
  mainCardStyle: {
    display: 'flex',
    cursor: 'pointer',
    boxShadow:'none',
    width:'29vw',
    padding:'0.5vw'
  },
  mainCardContentStyle: {
    padding: '0.25vw',
  },
  gridTitle:{
    height:'1.9vw',
    overflow:'hidden',
  },
  recentBlogTitleStyle: {
    fontWeight: 400,
    letterSpacing: '0.05vw',
    color: '#3e8010',
    fontFamily: "DIN2014-DemiBold",
    fontSize: '1.2vw'
  },
  recentBlogDescriptionStyle: {
    letterSpacing: '0.038vw',
    color: '#6F6C6C',
    fontFamily: "Avenir-Light",
    fontSize: '0.89vw',
  },
  mainSliderCardStyle: {
    width: '100%',
    height: '100%',
  },
  recentBlogDateStyle: {
    letterSpacing: '0.038vw',
    fontFamily: "Avenir-Book",
    fontSize: '0.89vw',
    color: '#000000'
  },
  backIconBoxStyle: {
    padding: '0.625vw 0.625vw 0.625vw 0.9375vw',
    background: '#3E8010',
    color: '#ffff',
    cursor: 'pointer',
    fontSize: '1.5vw'
  },
  iFrameBoxStyle: {
    width: '67.3125vw',
    height: '39.3125vw'
  },
  navigateNextIconStyle: {
    fontSize: '1.5vw',
  }
};
// Customizable Area End
