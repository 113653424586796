import React, {useEffect, useRef, useState} from "react";

import {
    // Customizable Area Start
 Typography,
 Box
  // Customizable Area End
} from "@material-ui/core";


// Customizable Area Start

import  { gsap } from 'gsap';
import { ScrollTrigger} from 'gsap/dist/ScrollTrigger';
import CircularProgress from '@material-ui/core/CircularProgress';
import { 
      sampleVideo,
      leafup, 
      Bitmap ,
      greenleaf1,
      greenleaf2,
      greenleafCenter1,
      greenleafCenter2 ,
      yellowleaf1 ,
      yellowleaf2 ,
      yellowleaf3,
      yellowleafBig,
      yellowGroup,
      } from "../../blocks/landingpage/src/assets";

gsap.registerPlugin(ScrollTrigger);

const SustainVideo = () => {
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  
    const bannerTextRef = useRef(null);
    const greenleaf1Ref = useRef(null);
    const leafupRef = useRef(null);
    const greenleaf2Ref = useRef(null);
    const yellowleaf2Ref = useRef(null);
    const yellowleafBigRef = useRef(null);
    const yellowleaf3Ref = useRef(null);
    const greenleafCenter1Ref = useRef(null);
    const greenleafCenter2Ref = useRef(null);
    const yellowGroupRef = useRef(null);
    const yellowleaf1Ref = useRef(null);


    useEffect(()=>{
      const elBannerText=bannerTextRef.current;
      const elGreenleaf1=greenleaf1Ref.current;
      const elLeafup=leafupRef.current;
      const elGreenleaf2=greenleaf2Ref.current;
      const elYellowleaf2=yellowleaf2Ref.current;
      const elYellowleafBig=yellowleafBigRef.current;
      const elYellowleaf3=yellowleaf3Ref.current;
      const elGreenleafCenter1=greenleafCenter1Ref.current;
      const elGreenleafCenter2=greenleafCenter2Ref.current;
      const elYellowGroup=yellowGroupRef.current;
      const elYellowleaf1=yellowleaf1Ref.current;
   
     gsap.to(elBannerText,  {opacity:0, duration:3 })
     gsap.to(elGreenleaf1,  {y:'66vw', duration:4})
     gsap.to(elLeafup,  {y:'66vw', duration:4})
     gsap.to(elGreenleaf2,  {y:'52vw', duration:4})
     gsap.to(elYellowleaf2,  {y:'41vw', duration:4})
     gsap.to(elYellowleafBig,  {y:'60vw', duration:4})
     gsap.to(elYellowleaf3,  {y:'61vw', duration:4})
     gsap.to(elGreenleafCenter1,  {y:'63vw', duration:4})
     gsap.to(elGreenleafCenter2,  {y:'66vw', duration:4})
     gsap.to(elYellowleaf1,  {y:'63vw', duration:4})
     gsap.to(elYellowGroup,  {y:'70vw', duration:6})

     },[]);

    
  return (

    // Customizable Area Start
    <>

 <Box style={webStyle.boxVideo}>
         <video 
         onContextMenu={e => e.preventDefault()} 
         style={{width:'100%', height: '100%'}} 
         src={sampleVideo} 
         autoPlay 
         loop 
         muted
         title="Sustainpedia video"
         onLoadStart={() => {setIsVideoLoaded(true);}}
         onLoadedData={() => {setIsVideoLoaded(false);}}
          >
         </video>
        </Box>
        {isVideoLoaded && (
                
                <Box  style={webStyle.boxVideoTextStyle}>
                  <CircularProgress style={{color:'#3e8010'}}/>
                  </Box>
            )}
        <Box  style={{opacity: isVideoLoaded ? 0 : 1, ...webStyle.boxVideoTextStyle}}>
           <Typography align='center' ref={bannerTextRef}
           style={{ textTransform: 'uppercase', ...webStyle.bannerHeader}}>
            Sustainablity
            </Typography>
        <div 
        
           style={{
                      WebkitMaskImage: `url(${Bitmap})`,
                      maskImage: `url(${Bitmap})`,
                      width:'90vw',
                      height:'50.5vw',
                      position : 'absolute'
                      
  }} >
             <Box> <img src={greenleaf1} style={webStyle.greenleaf1Style} ref={greenleaf1Ref} alt="video animation"></img></Box>
             <Box> <img src={leafup} style={webStyle.leafupStyle} ref={leafupRef} alt="video animation"></img></Box>
             <Box> <img src={greenleaf2} style={webStyle.greenleaf2Style} ref={greenleaf2Ref} alt="video animation"></img></Box>
             <Box><img src={yellowleaf2} style={webStyle.yellowleaf2Style} ref={yellowleaf2Ref} alt="video animation"></img></Box>
             <Box><img src={yellowleafBig} style={webStyle.yellowleafBigStyle} ref={yellowleafBigRef} alt="video animation"></img></Box>
             <Box><img src={yellowleaf3} style={webStyle.yellowleaf3Style} ref={yellowleaf3Ref} alt="video animation"></img></Box>
             <Box><img src={greenleafCenter1} style={webStyle.greenleafCenter1Style} ref={greenleafCenter1Ref} alt="video animation"></img></Box>
             <Box><img src={greenleafCenter2} style={webStyle.greenleafCenter2Style} ref={greenleafCenter2Ref} alt="video animation"></img></Box>
             <Box><img src={yellowGroup} style={webStyle.yellowGroupStyle} ref={yellowGroupRef} alt="video animation"></img></Box>
             <Box><img src={yellowleaf1} style={webStyle.yellowleaf1Style} ref={yellowleaf1Ref} alt="video animation"></img></Box>
              
          </div>
        </Box>
        
    </>
  );
}

// Customizable Area Start
const webStyle = {
  
  boxVideo:{
    position:'relative' as 'relative',
    display:'flex',
    flexDirection:'column' as 'column',
    alignItems:'center',
    justifyContent:'center',
    width:'90%',
  },
  boxVideoTextStyle:{
    position:'absolute' as 'absolute',
    display:'flex',
    flexDirection:'column' as 'column',
    alignItems:'center',
    justifyContent:'center',
    width:'50%',
   
    },
    bannerHeader:{
      fontFamily: 'BCAlphapipeTSB-Bold',
      fontWeight: 600,
      fontSize: '5vw',
      lineHeight: '6vw',
      letterSpacing: '0.3vw',
      color:'#3E8010',
    },
    
    greenleaf1Style:{ 
      marginTop:'5vw', 
      marginLeft:'6vw',
      width:'2.7vw', 
      height:'2vw'
    },
    leafupStyle:{ 
      marginTop:'8vw', 
      marginLeft:'2vw',
      width:'1.9vw', 
      height:'2.5vw'
    },
    greenleaf2Style:{
       marginTop:'-18.5vw',
       marginLeft:'71vw',
       width:'2vw',
       height:'1.45vw'
     },
    yellowleaf2Style:{
       marginTop:'-7vw',
       marginLeft:'75vw',
       width:'1.9vw', 
       height:'2.5vw',
      },
    yellowleafBigStyle:{ 
      marginTop:'-24vw',
      marginLeft:'25vw',
      width:'2.7vw', 
      height:'3.47vw'
     },
    yellowleaf3Style:{ 
      marginTop:'-27vw',
      marginLeft:'28vw',
      width:'1.9vw', 
      height:'2.5vw',
     },
    greenleafCenter1Style:{ 
      marginTop:'-30vw',
      marginLeft:'41vw',
      width:'2.7vw', 
      height:'2vw'
    },
    greenleafCenter2Style:{
       marginTop:'-30vw',
       marginLeft:'65vw',
       width:'2.7vw', 
       height:'2vw'
    },
    yellowGroupStyle:{
       marginTop:'-30vw',
       marginLeft:'74vw',
       width:'10.9vw',
       height:'5.2vw'
    },
    yellowleaf1Style:{
       marginTop:'-30vw',
       marginLeft:'3vw',
       width:'2.7vw',
       height:'1.8vw'
    }

};

export default SustainVideo;
// Customizable Area End
